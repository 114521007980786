import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Factura = ({ factura }) => {
  const [expanded, setExpanded] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectFactura, setSelectFactura] = useState([]);
  const [edit, setEdit] = useState(false);

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const formattedDate = getCurrentDate();
  console.log(formattedDate);

  function isDateAfter(dateStr) {
    const currentDate = new Date();
    const targetDate = new Date(dateStr.split("-").reverse().join("-"));

    return targetDate > currentDate;
  }

  const handleRowClick = () => {
    setExpanded(!expanded);
  };

  const handleFacturi = (x) => {
    setOpen2(!open2);
    setSelectFactura(x);
  };

  function getInvoiceAmount(facturi) {
    let totalAmount = 0;

    Object.values(facturi.luna).forEach((amount) => {
      totalAmount += amount;
    });

    return totalAmount;
  }

  return (
    <React.Fragment>
      <tr>
        <td onClick={handleRowClick}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginLeft: "150px",
              width: "400px",
            }}
          >
            {factura.nume}
            {isDateAfter(factura.scadenta) ? (
              <span> {factura.scadenta}</span>
            ) : (
              <span style={{ color: "red" }}> {factura.scadenta}</span>
            )}

            {`${getInvoiceAmount(factura)} Euro`}
          </div>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
        <td>
          {" "}
          <Button onClick={() => handleFacturi(factura)}>Editeaza Avans</Button>
        </td>
      </tr>
      {/* {expanded && <FacturaDetails factura={factura} />} */}

      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {!edit ? (
                  <h2>{selectFactura.nume}</h2>
                ) : (
                  <input placeholder={selectFactura.nume} />
                )}
                {!edit ? (
                  <p> Scadenta: {selectFactura.scadenta}</p>
                ) : (
                  <>
                    {" "}
                    <br /> <input placeholder={selectFactura.scadenta} />{" "}
                  </>
                )}
              </div>
              <Button onClick={() => setEdit(!edit)}>Edit Avans</Button>
            </div>
            <br />
            <br />
            <h4>Luna:</h4>
            <p style={{ width: "350px" }}>
              Ianuarie:{" "}
              {!edit ? (
                selectFactura?.luna?.ianuarie
              ) : (
                <input placeholder={selectFactura?.luna?.ianuarie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Februarie:{" "}
              {!edit ? (
                selectFactura?.luna?.februarie
              ) : (
                <input placeholder={selectFactura?.luna?.februarie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Martie:{" "}
              {!edit ? (
                selectFactura?.luna?.martie
              ) : (
                <input placeholder={selectFactura?.luna?.martie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Aprilie:{" "}
              {!edit ? (
                selectFactura?.luna?.aprilie
              ) : (
                <input placeholder={selectFactura?.luna?.aprilie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Mai:{" "}
              {!edit ? (
                selectFactura?.luna?.mai
              ) : (
                <input placeholder={selectFactura?.luna?.mai} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Iunie:{" "}
              {!edit ? (
                selectFactura?.luna?.iunie
              ) : (
                <input placeholder={selectFactura?.luna?.iunie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Iulie:{" "}
              {!edit ? (
                selectFactura?.luna?.iulie
              ) : (
                <input placeholder={selectFactura?.luna?.iulie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              August:{" "}
              {!edit ? (
                selectFactura?.luna?.august
              ) : (
                <input placeholder={selectFactura?.luna?.august} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Septembrie:{" "}
              {!edit ? (
                selectFactura?.luna?.septembrie
              ) : (
                <input placeholder={selectFactura?.luna?.septembrie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Octombrie:{" "}
              {!edit ? (
                selectFactura?.luna?.octombrie
              ) : (
                <input placeholder={selectFactura?.luna?.octombrie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Noiembrie:{" "}
              {!edit ? (
                selectFactura?.luna?.noiembrie
              ) : (
                <input placeholder={selectFactura?.luna?.noiembrie} />
              )}
            </p>
            <p style={{ width: "350px" }}>
              Decembrie:{" "}
              {!edit ? (
                selectFactura?.luna?.decembrie
              ) : (
                <input placeholder={selectFactura?.luna?.decembrie} />
              )}
            </p>
            {edit ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button>Salveaza</Button>
              </div>
            ) : null}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Factura;
