import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const DetaliiModal = ({ open, comanda, handleClose }) => {
  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            gap: "4px",
            display: "flex",
            flexDirection: "column",
            padding: 8,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: 500, fontSize: 22 }}>
              Comanda: {comanda?.comandaId}
            </span>
          </div>
          <hr />

          <div
            style={{ border: "1px solid grey", borderRadius: 8, padding: 4 }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    Producator
                  </th>
                  <th style={{ paddingRight: "40px" }}>Model</th>

                  <th style={{ paddingRight: "40px" }}>Numar inventar</th>

                  <th style={{ paddingRight: "40px" }}>Serie S/N</th>

                  <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
                  <th style={{ paddingRight: "40px" }}>Pret Achizitie</th>

                  <th style={{ paddingRight: "40px" }}>TVA</th>
                </tr>
              </thead>
              <tbody>
                {comanda?.produse?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                      <span>{item.producator}</span>
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.model}</td>

                    <td style={{ paddingRight: "40px" }}>
                      {item.numarInventar}
                    </td>

                    <td style={{ paddingRight: "40px" }}>{item.serieSN}</td>

                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.pretV)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.pretA)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.tva}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DetaliiModal;
