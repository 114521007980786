import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

export default function Alimentare({
  open,
  handleClose,
  selectedDriver,
  handleInputChangeNewAlimentare,
  handleAddNewAlimentare,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adauga alimentare: {selectedDriver?.nume}
          </Typography>
          <br />
          <>
            <TextField
              id="outlined-basic"
              label="Benzinarie"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewAlimentare("benzinarie", e.target.value)
              }
            />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Cantitate alimentata (Litri)"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewAlimentare("cantitate", e.target.value)
              }
            />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Valoare alimentare (Suma)"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewAlimentare("suma", e.target.value)
              }
            />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Data alimentare"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewAlimentare("dataAlimentare", e.target.value)
              }
            />
          </>
          <br />
          <br />
          <Button variant="contained" onClick={handleAddNewAlimentare}>
            Adauga alimentare
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
