import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import md5 from "md5";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import sma from "../../../images/smaTitle.png";
import "./LoginPage.css";

const headerStyle = {
  fontSize: "40px",
  fontWeight: 500,
};

const textFieldStyle = {
  marginBottom: "16px",
  width: "100%",
};

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();

  const currentDate = new Date();

  const sevenDaysLater = new Date(currentDate);
  const expireDate = sevenDaysLater.setDate(currentDate.getDate() + 7);

  // const futureDate = new Date(currentDate.getTime() + 10 * 1000);

  const handleEmailChange = (event) => {
    const trimmedEmail = event.target.value.trim();
    setEmail(trimmedEmail);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // const handleSignUpClick = async (e) => {
  //   e.preventDefault();

  //   if (!validateEmail(email)) {
  //     alert("Invalid email address");
  //     return;
  //   } else if (!password.trim()) {
  //     {
  //       alert("Please enter a password");
  //       return;
  //     }
  //   } else {
  //     try {
  //       const encryptedPassword = md5(password);

  //       const response = await fetch("https://jellyfish-app-f8wy8.ondigitalocean.app/api/users", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email, password: encryptedPassword }),
  //       });

  //       if (!response.ok) {
  //         const error = await response.json();
  //         throw new Error(error.message);
  //       }
  //       setIsAuthenticated(true);
  //     } catch (error) {
  //       setError(error.message);
  //       console.log(error);
  //     }
  //   }
  // };

  const handleLoginClick = async (e) => {
    e.preventDefault();

    try {
      const encryptedPassword = md5(password);

      const response = await fetch("https://jellyfish-app-f8wy8.ondigitalocean.app/api/users/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password: encryptedPassword }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }

      const loginData = await response.json();

      localStorage.setItem("expireDate", expireDate);
      localStorage.setItem("login", true);

      setUserData(loginData);
      setIsAuthenticated(true);
      navigate("/");
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        marginTop: "7%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="login-screen">
        <Typography style={headerStyle}>Welcome to</Typography>
        <img src={sma} width="300px" alt="logo" />
        <br />
        <br />

        <Typography>
          We are excited to have you on board! Please log in to access our
          platform and explore!
        </Typography>
        <br />
        {error && <div className="error">{error}</div>}

        <form onSubmit={handleLoginClick}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            style={textFieldStyle}
            value={email}
            onChange={handleEmailChange}
            required
          />
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            style={textFieldStyle}
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleLoginClick}
            >
              Login
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              // onClick={handleSignUpClick}
              disabled
            >
              Sign up
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
