import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Collapse } from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../utils/rapoarte/raportInventarCentralPDF";
import { generateExcelFile } from "./rapoarte/raportXLSX";
import "./listaGestiune.css";
import { useAuth } from "../../common/Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import { useDepozitContext } from "../gestiuneEco/context/AuthContext";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NouaLista = () => {
  const { userData } = useAuth();
  const { isNewDepozit, setIsNewDepozit } = useDepozitContext();

  const [openMaterial, setOpenMaterial] = useState(false);
  const [material, setMaterial] = useState([]);
  const [openMutare, setOpenMutare] = useState(false);
  const [materialId, setMaterialId] = useState();

  const [nume, setNume] = useState();
  const [numeFirma, setNumeFirma] = useState();
  const [model, setModel] = useState();
  const [categorieProdus, setCategorieProdus] = useState();
  const [pretA, setPretA] = useState();
  const [pretV, setPretV] = useState();
  const [tva, setTva] = useState();
  const [stoc, setStoc] = useState();

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);
  const [trigger, setTrigger] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const uniqueCategories = [...new Set(material.map((item) => item.categorie))];

  const filterItem = (curcat, field) => {
    const newItem = material.filter((newVal) => {
      return newVal[field] === curcat;
    });

    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handleClose = () => {
    setOpenMaterial(false);
    setOpenMutare(false);
    setNume("");
    setNumeFirma("");
    setModel("");
    setStoc("");
    setPretA("");
    setPretV("");
    setTva("");
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
    setQuantity("");
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    setSelectedButton(newSelectedButton);
  };

  const handleInputStoc = (e) => {
    const rawValue = e.target.value.replace(/\./g, "");
    setStoc(rawValue);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus();
  };

  const errorHandler = () => {
    if (quantity > selectedMaterial.stoc) {
      return true;
    }
    return false;
  };

  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  const createMaterial = async () => {
    const supplierData = {
      nume,
      numeFirma,
      model,
      categorie: newCategory !== "" ? newCategory : categorieProdus,
      pretA,
      pretV,
      cantitateDepozit: 0,
      cantitateSantier: 0,
      stoc,
      tva,
    };

    try {
      const response = await fetch(
        `https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        setOpenMaterial(!openMaterial);
        setTrigger(supplierData);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialDepozit = async () => {
    const supplierData = {
      materialId: materialId,
      quantity,
      depozitId: selectedDepozit,
    };

    try {
      const response = await fetch(
        `https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material/move-to-depozit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);

    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  const moveMaterialSantier = async () => {
    const supplierData = {
      materialId: materialId,
      quantity,
      santierId: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material/move-to-santier`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Santier");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [santiersResponse, depozitsResponse, materialResponse] =
          await Promise.all([
            fetch("https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/santier").then(
              (response) => response.json()
            ),
            fetch("https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/depozit").then(
              (response) => response.json()
            ),
            fetch("https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material", {
              credentials: "include",
            }).then((response) =>
              response.ok ? response.json() : Promise.reject(response)
            ),
          ]);

        setSantiers(santiersResponse);
        setDepozits(depozitsResponse);

        setMaterial(materialResponse);
        setFilteredMaterials(materialResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [trigger, openMaterial, openMutare]);

  useEffect(() => {
    setMaterialId(selectedMaterial._id);
  }, [selectedMaterial]);

  //---------------Edit feature-------------

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  async function updateMaterial(e) {
    e.preventDefault();

    const editedItem = {
      nume: formEdit.nume,
      numeFirma: formEdit.numeFirma,
      model: formEdit.model,
      numarInventar: formEdit.numarInventar,
      categorie: newCategory !== "" ? newCategory : formEdit.categorie,
      stoc: formEdit.stoc,
      author: userData?.name,
    };

    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material/${formEdit._id}`, {
      method: "PUT",
      body: JSON.stringify(editedItem),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setTrigger(formEdit?.nume);
  }

  const handleCloseEdit = (e) => {
    updateMaterial(e);
    setOpenEdit(false);
  };

  const [openDelete, setOpenDelete] = useState();

  async function deleteMaterial() {
    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/gestiune/material/${formEdit._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenDelete(false);
    setOpenEdit(false);
    setTrigger(formEdit?.nume);
  }

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>Depozit</h3>
            <span>Materiale: {material.length}</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMaterial(true)}
            >
              Adauga Material
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMutare(!openMutare)}
            >
              Muta Materiale
            </Button>

            {/* <TextField
              type="text"
              placeholder="Cauta dupa nume..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="desktop-grid-agro">
        <div className="desktop-agro">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
          </div>

          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setFilteredMaterials(material)}
            >
              Toate
            </Button>
          </div>

          {uniqueCategories.map((category) => (
            <div style={{ marginTop: "8px" }}>
              <Button
                key={category}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
                onClick={() => filterItem(category, "categorie")}
              >
                {category}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <div className="mobile-agro">
            <Button
              variant="contained"
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
            >
              Categorii
            </Button>
            <Collapse in={openCategoryMobile}>
              <div
                style={{
                  borderRight: "1px solid #ccc",
                  padding: "12px",
                  borderRadius: "4px",
                  marginRight: "4px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "grey.dark",
                      color: "white", // normal background color
                      "&:hover": {
                        bgcolor: "grey.light",
                        color: "black", // hover background color
                      },
                      width: "20%",
                    }}
                    onClick={() => handleAllCategory(material)}
                  >
                    Toate
                  </Button>
                </div>

                {uniqueCategories.map((category) => (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      key={category}
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: "grey.dark",
                        color: "white", // normal background color
                        "&:hover": {
                          bgcolor: "grey.light",
                          color: "black", // hover background color
                        },
                        width: "20%",
                      }}
                      onClick={() => filterItem(category, "categorie")}
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          <div style={{ maxHeight: "68vh", overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    Nume
                  </th>
                  <th style={{ paddingRight: "40px" }}>Producator</th>
                  <th style={{ paddingRight: "40px" }}>Model</th>
                  <th style={{ paddingRight: "40px" }}>Categorie</th>
                  <th style={{ paddingRight: "40px" }}>Pret Achizitie</th>
                  <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
                  <th style={{ paddingRight: "40px" }}>TVA</th>
                  <th style={{ paddingRight: "40px" }}>Cantitate Depozite</th>
                  <th style={{ paddingRight: "40px" }}>Cantitate Santier</th>
                  <th style={{ paddingRight: "40px" }}>Stoc Depozit</th>
                </tr>
              </thead>
              <tbody>
                {filteredMaterials.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                      <Tooltip
                        title={
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            adaugat de {item.author} la{" "}
                            {formatCustomDateString(item.createdAt)}
                          </Typography>
                        }
                        placement="right"
                      >
                        <span>{item.nume}</span>
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.numeFirma}</td>
                    <td style={{ paddingRight: "40px" }}>{item.model}</td>
                    <td style={{ paddingRight: "40px" }}>{item.categorie}</td>
                    <td style={{ paddingRight: "40px" }}>{item.pretA}</td>
                    <td style={{ paddingRight: "40px" }}>{item.pretV}</td>
                    <td style={{ paddingRight: "40px" }}>{item.tva}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {item.cantitateDepozit}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {item.cantitateSantier}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.stoc)}{" "}
                      <Tooltip
                        title={
                          <span style={{ fontSize: 16 }}>
                            {formatNumberWithDots(
                              Math.max(
                                0,
                                item.stoc -
                                  item.cantitateDepozit -
                                  item.cantitateSantier
                              )
                            )}{" "}
                            nefolosite
                          </span>
                        }
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "blue.dark", // normal background color
                          "&:hover": {
                            bgcolor: "blue.light", // hover background color
                          },
                        }}
                        onClick={() => handleEditForm(item)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PDFDownloadLink
          document={
            <Factura
              materiale={material}
              section={"inventar"}
              date={currentDate}
            />
          }
          fileName={`${"Raport "}${"inventar"}${" "}${currentDate}${".pdf"}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Generating PDF..."
            ) : (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "blue.focus", // normal background color
                  "&:hover": {
                    bgcolor: "blue.focusHover", // hover background color
                  },
                }}
              >
                {" "}
                Genereaza Raport Inventar
              </Button>
            )
          }
        </PDFDownloadLink>{" "}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "8px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "green.light", // normal background color
            "&:hover": {
              bgcolor: "green.dark", // hover background color
            },
          }}
          onClick={() => generateExcelFile(material, currentDate)}
        >
          Genereaza Raport XLSX
        </Button>
      </div>

      <Modal
        open={openMaterial}
        onClose={handleModalMaterialClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adauga Material Nou</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume Material"
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <TextField
              type="text"
              placeholder="Firma material"
              onChange={(e) => setNumeFirma(e.target.value)}
            ></TextField>
            <TextField
              type="text"
              placeholder="Model"
              onChange={(e) => setModel(e.target.value)}
            ></TextField>
            <br />
            <br />

            <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.categorie}
              onChange={(e) => {
                updateForm({ categorie: e.target.value });
                setNewCategory(""); // Reset the new category input when changing the selection
              }}
            >
              {uniqueCategories.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adauga Categorie Noua</MenuItem>
            </Select>

            {formEdit.categorie === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}

            <TextField
              type="text"
              placeholder="Pret Achizitie"
              onChange={(e) => setPretA(e.target.value)}
            ></TextField>
            <TextField
              type="text"
              placeholder="Pret Vanzare"
              onChange={(e) => setPretV(e.target.value)}
            ></TextField>
            <TextField
              type="text"
              placeholder="TVA"
              onChange={(e) => setTva(e.target.value)}
            ></TextField>

            <TextField
              type="text"
              placeholder="Stoc"
              value={stoc}
              onChange={handleInputStoc}
            ></TextField>

            <br />
            <br />
            <Button
              variant="contained"
              sx={{ bgcolor: "green.light" }}
              onClick={() => createMaterial()}
            >
              {" "}
              Adauga Material
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openMutare}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Muta materialele</h2>
            <br />
            <ToggleButtonGroup
              value={selectedButton}
              exclusive
              onChange={handleButtonChange}
            >
              <ToggleButton value="depozit">Depozit</ToggleButton>
              <ToggleButton value="santier">Santier</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            {selectedButton === "santier" ? (
              <>
                {" "}
                <div style={{ width: "100%", gap: "5px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Material
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedMaterial}
                    onChange={handleMaterialChange}
                    label="Material"
                    style={{ width: "30%", marginRight: "10px" }}
                  >
                    {material.map((material) => (
                      <MenuItem key={material._id} value={material}>
                        {material.nume} - {material.model}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    label="Quantity"
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                  <Select
                    value={selectedSantier}
                    onChange={handleSantierChange}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {santiers.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => moveMaterialSantier()}
                    variant="contained"
                    sx={{ bgcolor: "blue.light" }}
                  >
                    Muta in Plantatie
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Material
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedMaterial}
                    onChange={handleMaterialChange}
                    label="Material"
                    style={{ width: "30%", marginRight: "10px" }}
                  >
                    {material.map((material) => (
                      <MenuItem key={material._id} value={material}>
                        {material.nume} - {material.model}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    label="Cantitate"
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                  <Select
                    value={selectedDepozit}
                    onChange={(e) => handleDepozitChange(e)}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {depozits.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => moveMaterialDepozit()}
                    variant="contained"
                    sx={{ bgcolor: "blue.light" }}
                  >
                    Muta in Depozit
                  </Button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Editeaza Material: {formEdit.nume}</h2>
              <Tooltip
                title={
                  <span style={{ fontSize: 14 }}>
                    Editat de {formEdit.author} in{" "}
                    {formatCustomDateString(formEdit.updatedAt)}
                  </span>
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <br />
            <InputLabel id="demo-simple-select-label">
              Nume Material{" "}
            </InputLabel>

            <TextField
              type="text"
              placeholder="Nume Material"
              value={formEdit.nume}
              onChange={(e) => updateForm({ nume: e.target.value })}
            ></TextField>
            <InputLabel id="demo-simple-select-label">
              Firma Material{" "}
            </InputLabel>

            <TextField
              type="text"
              placeholder="Firma material"
              value={formEdit.numeFirma}
              onChange={(e) => updateForm({ numeFirma: e.target.value })}
            ></TextField>
            <InputLabel id="demo-simple-select-label">Model </InputLabel>

            <TextField
              type="text"
              placeholder="Model"
              value={formEdit.model}
              onChange={(e) => updateForm({ model: e.target.value })}
            ></TextField>

            <InputLabel id="demo-simple-select-label">
              Numar inventar{" "}
            </InputLabel>

            <br />
            <br />

            <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.categorie}
              onChange={(e) => {
                updateForm({ categorie: e.target.value });
                setNewCategory(""); // Reset the new category input when changing the selection
              }}
            >
              {uniqueCategories.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adauga Categorie Noua</MenuItem>
            </Select>

            {formEdit.categorie === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <InputLabel id="demo-simple-select-label">
              Pret Achizitie{" "}
            </InputLabel>

            <TextField
              type="text"
              placeholder="Pret Achizitie"
              value={formEdit.pretA}
              onChange={(e) => updateForm({ pretA: e.target.value })}
            />

            <InputLabel id="demo-simple-select-label">Pret Vanzare </InputLabel>

            <TextField
              type="text"
              placeholder="Pret Vanzare"
              value={formEdit.pretV}
              onChange={(e) => updateForm({ pretV: e.target.value })}
            />

            <InputLabel id="demo-simple-select-label">Stoc </InputLabel>

            <TextField
              type="text"
              placeholder="Stoc"
              value={formEdit.stoc}
              onChange={(e) => updateForm({ stoc: e.target.value })}
            ></TextField>

            <InputLabel id="demo-simple-select-label">TVA</InputLabel>

            <TextField
              type="text"
              placeholder="TVA"
              value={formEdit.tva}
              onChange={(e) => updateForm({ tva: e.target.value })}
            />

            <br />

            <Button
              variant="contained"
              sx={{
                bgcolor: "green.light", // normal background color
                "&:hover": {
                  bgcolor: "green.dark", // hover background color
                },
              }}
              onClick={handleCloseEdit}
            >
              {" "}
              Actualizare
            </Button>

            {userData?.isAdmin && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    bgcolor: "red.dark", // normal background color
                    "&:hover": {
                      bgcolor: "red.light", // hover background color
                    },
                  }}
                  onClick={() => setOpenDelete(true)}
                >
                  Elimina
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>{formEdit?.nume}</b>
            Sigur stergeti materialul?
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                color="error"
                sx={{ bgcolor: "red.light" }}
                onClick={handleDeleteMaterial}
              >
                Da
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenDelete(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NouaLista;
