import React, { useState } from "react";
import Contract from "./contracte";
import { Modal, Box, Button, TextField } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = (props) => {
  const furnizor = props.record;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [edit, setEdit] = useState(false);

  const [nume, setNume] = useState();
  const [valoare, setValoare] = useState();
  const [data, setData] = useState();

  console.log(furnizor);

  const [expanded, setExpanded] = useState(false);

  const handleRowClick = () => {
    setExpanded(!expanded);
  };

  const createContract = async () => {
    const cleanedValoare = parseFloat(valoare.replace(/[\.,]/g, ""));

    const supplierData = {
      numeContract: nume,
      valoareContract: cleanedValoare, // Assuming valoare is a number
      dateContract: data,
    };

    try {
      const response = await fetch(
        `https://modvin-erb-tyu-4e6087587d89.herokuapp.com/api/suppliers/${furnizor._id}/contracts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        setOpen2(!open2);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  console.log(furnizor);

  return (
    <React.Fragment>
      <tr>
        <td
          onClick={handleRowClick}
          style={{ marginTop: "10px", width: "80%" }}
        >
          <h3>{furnizor.nume}</h3>
          <span>Contracte {furnizor.contracte.length}</span>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
        <td>
          <Button variant="contained" onClick={() => setOpen(!open)}>
            Editeaza Furnizor
          </Button>
        </td>
      </tr>
      {expanded && (
        <>
          {furnizor.contracte.map((contract, contractIndex, index) => (
            <Contract furnizorId={furnizor._id} contract={contract} />
          ))}
        </>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>Editeaza Furnizor</h2>
              <div>
                <Button onClick={() => setEdit(!edit)}>Edit</Button>
                {!edit ? null : (
                  <Button onClick={() => setEdit(!edit)}>Save</Button>
                )}
              </div>
            </div>

            {edit ? (
              <TextField type="text" placeholder={furnizor.nume}></TextField>
            ) : (
              <p>{furnizor.nume}</p>
            )}

            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>Contracte: </h3>
              <Button onClick={() => setOpen2(!open2)}> Adauga Contract</Button>
            </div>
            {furnizor.contracte.map((contract, contractIndex, index) => (
              <p>{contract.numeContract}</p>
            ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Contract</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume Contract"
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Valoare Contract"
              onChange={(e) => setValoare(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Data Contract"
              onChange={(e) => setData(e.target.value)}
            ></TextField>
            <br />
            <br />
            <Button onClick={() => createContract()}> Adauga Contract</Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Record;
