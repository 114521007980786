import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import yourImage from "../../../images/truck11.png";

const HartaMasini = ({ selectedDriverLocation, selectedDriverData }) => {
  const position = [
    selectedDriverLocation.latitude,
    selectedDriverLocation.longitude,
  ];

  const customIcon = new L.Icon({
    iconUrl: yourImage,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: "90%", width: "90%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          <b>Motor:</b> {selectedDriverData.ignitionState} <br />{" "}
          <b>Durata cursa:</b> 1h 23m <br /> <b>Viteza:</b>{" "}
          {selectedDriverData.speed} km/h
          <br /> <b>Consum:</b> 8,5L <br /> <b>Rezervor:</b> 45L
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default HartaMasini;
