import React from "react";
import {
  Modal,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";

const MutareMaterialModal = ({
  openMutare,
  handleClose,
  selectedButton,
  handleButtonChange,
  selectedMaterial,
  handleMaterialChange,
  material,
  quantity,
  handleQuantityChange,
  errorHandler,
  selectedSantier,
  handleSantierChange,
  santiers,
  selectedDepozit,
  handleDepozitChange,
  depozits,
  moveMaterialSantier,
  moveMaterialDepozit,
  styleInv,
}) => {
  return (
    <Modal
      open={openMutare}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleInv}>
        <div>
          <h2>Muta materialele</h2>
          <br />
          <ToggleButtonGroup
            value={selectedButton}
            exclusive
            onChange={handleButtonChange}
          >
            <ToggleButton value="depozit">Depozit</ToggleButton>
            <ToggleButton value="santier">Plantatie</ToggleButton>
          </ToggleButtonGroup>
          <br />
          <br />
          <div style={{ width: "100%", gap: "5px" }}>
            <InputLabel id="demo-simple-select-label">Material</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedMaterial}
              onChange={handleMaterialChange}
              label="Material"
              style={{ width: "30%", marginRight: "10px" }}
            >
              {material.map((mat) => (
                <MenuItem key={mat._id} value={mat}>
                  {mat.nume} - {mat.model}
                </MenuItem>
              ))}
            </Select>
            <TextField
              value={quantity}
              onChange={handleQuantityChange}
              error={errorHandler()}
              helperText={errorHandler() ? "Stoc insuficient" : ""}
              label={selectedButton === "santier" ? "Quantity" : "Cantitate"}
              style={{ width: "30%", marginRight: "10px" }}
            />
            <Select
              value={
                selectedButton === "santier" ? selectedSantier : selectedDepozit
              }
              onChange={
                selectedButton === "santier"
                  ? handleSantierChange
                  : handleDepozitChange
              }
              placeholder={selectedButton === "santier" ? "Santier" : "Depozit"}
              style={{ width: "30%" }}
            >
              {(selectedButton === "santier" ? santiers : depozits).map(
                (loc) => (
                  <MenuItem key={loc._id} value={loc._id}>
                    {loc.nume} - {loc.locatie}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginRight: "60px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={
                selectedButton === "santier"
                  ? moveMaterialSantier
                  : moveMaterialDepozit
              }
              variant="contained"
              sx={{ bgcolor: "blue.light" }}
            >
              Muta in {selectedButton === "santier" ? "Plantatie" : "Depozit"}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MutareMaterialModal;
