export const clienti = [
    {
      nume: "Client 1",
      facturi: [
        {
          nume: "factura 1",
          avans: [
            {
              nume: "avans 1",
              luna: 
                {
                  ianuarie: 0 ,
                  februarie: 0,
                  martie: 300,
                  aprilie: 0,
                  mai: 500,
                  iunie: 600,
                  iulie: 0,
                  august: 800,
                  septembrie: 900,
                  octombrie: 0,
                  noiembrie: 0,
                  decembrie: 1200,
                },
                scadenta: '12-05-2023'
              
            },
            {
              nume: "avans 2",
              luna: 
                {
                  ianuarie: 0 ,
                  februarie: 0,
                  martie: 300,
                  aprilie: 0,
                  mai: 500,
                  iunie: 600,
                  iulie: 0,
                  august: 800,
                  septembrie: 900,
                  octombrie: 0,
                  noiembrie: 0,
                  decembrie: 1200,
                },
                scadenta: '24-05-2023'
              
            },
          ],
          valoareContract: '10000 Euro',
          dataContract: '12-07-2023'
        },
        {
          nume: "factura 2",
          avans: [
            {
              nume: "avans 1",
              luna: 
                {
                  ianuarie: 100 ,
                  februarie: 200,
                  martie: 300,
                  aprilie: 400,
                  mai: 500,
                  iunie: 600,
                  iulie: 700,
                  august: 800,
                  septembrie: 900,
                  octombrie: 1000,
                  noiembrie: 1100,
                  decembrie: 1200,
                },
                scadenta: '30-05-2023'
  
            },
          ],
          valoareContract: '100000 Euro',
          dataContract: '12-07-2023'
        },
      ],
    },
    {
      nume: "Client 2",
      facturi: [
        {
          nume: "factura 1",
          avans: [
            {
              nume: "avans 1",
              luna: 
                {
                  ianuarie: 100 ,
                  februarie: 200,
                  martie: 300,
                  aprilie: 400,
                  mai: 500,
                  iunie: 600,
                  iulie: 700,
                  august: 800,
                  septembrie: 900,
                  octombrie: 1000,
                  noiembrie: 1100,
                  decembrie: 1200,
                },
                scadenta: '23-05-2023',
  
            },
          ],
          valoareContract: '10000 Euro',
          dataContract: '22-06-2022'
        },
      ],
    },
    {
      nume: "Client 3",
      facturi: [
        {
          nume: "factura 1",
          avans: [
            {
              nume: "avans 1",
              luna: 
                {
                  ianuarie: 100 ,
                  februarie: 200,
                  martie: 300,
                  aprilie: 400,
                  mai: 500,
                  iunie: 600,
                  iulie: 700,
                  august: 800,
                  septembrie: 900,
                  octombrie: 1000,
                  noiembrie: 1100,
                  decembrie: 1200,
                },
                scadenta: '12-08-2024'
  
            },
          ],
          valoareContract: '200000 Euro',
          dataContract: '12-04-2023'
        },
      ],
    },
  ];
  