import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Button } from "@mui/material";

export default function Edit({ id }) {
  const [form, setForm] = useState({
    nume: "",
    telefon: "",
    Adresa: "",
    Data: "",
    Lucrare: "",
    proiect: "",
    RTL: "",
    Localitate: "",
  });
  const params = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchData() {
  //     const id = params.id.toString();
  //     const response = await fetch(
  //       `http://164.92.178.125:5050/record/${params.id.toString()}`
  //     );

  //     if (!response.ok) {
  //       const message = `An error has occurred: ${response.statusText}`;
  //       window.alert(message);
  //       return;
  //     }

  //     const record = await response.json();
  //     if (!record) {
  //       window.alert(`Record with id ${id} not found`);
  //       navigate("/");
  //       return;
  //     }

  //     setForm(record);
  //   }

  //   fetchData();

  //   return;
  // }, [params.id, navigate]);

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();

    const editedPerson = {
      nume: form.nume,
      telefon: form.telefon,
      Adresa: form.Adresa,
      Data: form.Data,
      Lucrare: form.Lucrare,
      proiect: form.proiect,
      RTL: form.RTL,
      Localitate: form.Localitate,
      anexaArray: anexaArray,
    };

    console.log(editedPerson);

    // This will send a post request to update the data in the database.
    // await fetch(`http://164.92.178.125:5050/record/${params.id}`, {
    //   method: "PATCH",
    //   body: JSON.stringify(editedPerson),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });

    // navigate("/");
  }

  useEffect(() => {
    setForm(id);
  }, []);

  const [anexaArray, setAnexaArray] = useState([]);

  const [anexaItem, setAnexaItem] = useState({
    numeAnexa: "",
    contentAnexa: "",
  });

  const handleAddAnexa = () => {
    // Use the spread operator to create a new array with the existing items and add the new item
    setAnexaArray((prevArray) => [...prevArray, anexaItem]);

    // Clear the input fields after adding the item
    setAnexaItem({ numeAnexa: "", contentAnexa: "" });
  };

  const handleAnexaInputChange = (e, index) => {
    const { name, value } = e.target;
    setAnexaArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index][name] = value;
      return newArray;
    });
  };

  const handleDeleteAnexa = (index) => {
    const newAnexaArray = [...anexaArray];

    newAnexaArray.splice(index, 1);

    setAnexaArray(newAnexaArray);
  };

  const styleGrid =
    anexaArray.length > 0
      ? {
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gap: "8px",
        }
      : {};

  // This following section will display the form that takes input from the user to update the data.
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      ></div>
      <form>
        <div style={styleGrid}>
          <div>
            <label htmlFor="name">Nume</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={form.nume}
              onChange={(e) => updateForm({ nume: e.target.value })}
            />

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Telefon</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.telefon}
                  onChange={(e) => updateForm({ telefon: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Adresa</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.Adresa}
                  onChange={(e) => updateForm({ Adresa: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Data</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.Data}
                  onChange={(e) => updateForm({ Data: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Lucrare</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.Lucrare}
                  onChange={(e) => updateForm({ Lucrare: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Proiect</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.proiect}
                  onChange={(e) => updateForm({ proiect: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">RTL</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.RTL}
                  onChange={(e) => updateForm({ RTL: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="form-group">
                <label htmlFor="position">Localitate</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={form.Localitate}
                  onChange={(e) => updateForm({ Localitate: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%", // Set the parent div height to 100%
              marginTop: "10px",
            }}
          >
            {" "}
            <div>
              {anexaArray.map((item, index) => (
                <div style={{ marginTop: "14px" }} key={index}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="numeAnexa"
                      className="form-control"
                      placeholder={`Titlu ${index + 1}`}
                      value={item.numeAnexa}
                      onChange={(e) => handleAnexaInputChange(e, index)}
                    />

                    <input
                      type="text"
                      name="contentAnexa"
                      className="form-control"
                      placeholder="Continut"
                      value={item.contentAnexa}
                      onChange={(e) => handleAnexaInputChange(e, index)}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteAnexa(index)}
                    style={{ marginLeft: "10px" }}
                  >
                    X
                  </Button>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <div>
                <Button variant="contained" onClick={handleAddAnexa}>
                  Adauga Anexa
                </Button>
              </div>

              <div style={{ marginTop: "10px" }}>
                <div className="form-group">
                  <input
                    type="submit"
                    value="Actualizare"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
