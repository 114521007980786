import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const DetaliiComponent = ({ selectedDriver }) => {
  return (
    <List>
      <ListItem>
        <ListItemText primary={`Nume: ${selectedDriver?.nume}`} />
      </ListItem>
      <ListItem>
        <ListItemText primary={`Telefon: ${selectedDriver?.telefon}`} />
      </ListItem>
    </List>
  );
};

export default DetaliiComponent;
