import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const generateExcelFile = (data, depozit, date) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const includedFields = [
    "nume",
    "numeFirma",
    "model",
    "categorie",
    "cantitate",
  ];

  const includedFieldsIstoric = [
    "nume",
    "numeFirma",
    "model",
    "categorie",
    "cantitate",
    "author",
    "updatedAt",
  ];

  // Filter the data to include only the specified fields
  const filteredData = data.materialeDepozit.map((item) => {
    const filteredItem = {};
    includedFields.forEach((field) => {
      filteredItem[field] = item[field];
    });
    return filteredItem;
  });

  const ws = XLSX.utils.json_to_sheet(filteredData);

  // Set background color for the first row
  ws["!cols"] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];
  ws["!rows"] = [
    {
      hidden: false,
      customHeight: false,
      outlineLevel: 0,
      collapsed: false,
      level: 0,
      thickTop: false,
      ph: true,
    },
  ];

  const istoricData = data.istoric.map((item) => {
    const filteredItem = {};

    // Loop through each field in includedFieldsIstoric to include them from materialData
    includedFieldsIstoric.forEach((field) => {
      if (field === "author") {
        filteredItem[field] = item[field];
      } else if (field === "updatedAt") {
        // Apply date formatting for the updatedAt field
        filteredItem[field] = formatDate(item.materialData[field]);
      } else if (item.materialData && item.materialData.hasOwnProperty(field)) {
        filteredItem[field] = item.materialData[field];
      }
    });

    return filteredItem;
  });

  const wsIstoric = XLSX.utils.json_to_sheet(istoricData);

  // Set background color for the first row
  wsIstoric["!cols"] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 40 },
  ];
  wsIstoric["!rows"] = [
    {
      hidden: false,
      customHeight: false,
      outlineLevel: 0,
      collapsed: false,
      level: 0,
      thickTop: false,
      ph: true,
    },
  ];

  let excelRowsData = [];

  data.istoricSantiere.forEach((santierItem, index) => {
    const { author, santier } = santierItem; // Assuming each item has an author and an istoric array

    // Add a row for the santier item with specified details
    excelRowsData.push({
      Nume: santier?.nume,
    });

    // Process each deleted item in the istoric array
    santier.istoric.forEach((deletedItem) => {
      // Add a row for each deleted item with details
      excelRowsData.push({
        Author: deletedItem?.author, // Author might not be needed for each deleted item, adjust as necessary
        Nume: deletedItem?.materialData?.nume,
        NumeFirma: deletedItem?.materialData?.numeFirma,
        Model: deletedItem?.materialData?.model,
        Categorie: deletedItem?.materialData?.categorie,
        Cantitate: deletedItem?.materialData?.cantitate,
        UpdatedAt: deletedItem?.materialData?.updatedAt
          ? formatDate(deletedItem.materialData.updatedAt)
          : "",
      });
    });

    // Add two empty rows after processing each istoricSantiere item, except after the last one

    excelRowsData.push({}, {}); // Two empty rows
  });

  const wsIstoricSantiere = XLSX.utils.json_to_sheet(excelRowsData);

  wsIstoricSantiere["!cols"] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 40 },
  ];
  wsIstoricSantiere["!rows"] = [
    {
      hidden: false,
      customHeight: false,
      outlineLevel: 0,
      collapsed: false,
      level: 0,
      thickTop: false,
      ph: true,
    },
  ];

  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, "Situatia actuala");
  XLSX.utils.book_append_sheet(wb, wsIstoric, "Istoric materiale");
  XLSX.utils.book_append_sheet(wb, wsIstoricSantiere, "Istoric santiere");

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `raport-${depozit}-${date}.xlsx`);
};
