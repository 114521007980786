import React, { useState } from "react";
import Contract from "./contracte";
import { Modal, Box, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = (props) => {
  const furnizor = props.record;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open2, setOpen2] = useState(false);

  console.log(furnizor);

  const [expanded, setExpanded] = useState(false);

  const handleRowClick = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <tr>
        <td
          onClick={handleRowClick}
          style={{ marginTop: "10px", width: "80%" }}
        >
          <h3>{furnizor.nume}</h3>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
        <td>
          <Button variant="contained" onClick={() => setOpen(!open)}>
            Editeaza Client
          </Button>
        </td>
      </tr>
      {expanded && (
        <>
          {furnizor.facturi.map((contract, contractIndex, index) => (
            <Contract contract={contract} />
          ))}
        </>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Editeaza Client</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!edit ? (
                <TextField type="text" placeholder={furnizor.nume} />
              ) : (
                <p>{furnizor.nume}</p>
              )}
              <div>
                <Button onClick={() => setEdit(!edit)}>Edit</Button>
                {!edit ? (
                  <Button onClick={() => setEdit(!edit)}>Save</Button>
                ) : null}
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>Facturi: </h3>
              <Button onClick={() => setOpen2(!open2)}> Adauga Facturi</Button>
            </div>
            {furnizor.facturi.map((contract, contractIndex, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>
                  <Button style={{ color: "red" }}>
                    <DeleteIcon />
                  </Button>
                  {contract.nume}
                </p>
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Factura Noua</h2>
            <br />
            <TextField type="text" placeholder="Nume Factura"></TextField>
            <br />
            <br />
            <TextField type="text" placeholder="Valoare Factura"></TextField>
            <br />
            <br />
            <TextField type="text" placeholder="Scadenta"></TextField>
            <br />
            <br />
            <Button onClick={() => setOpen2(!open2)}> Adauga Factura</Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Record;
