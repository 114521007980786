import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Modal,
  TextField,
  Tooltip,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import MaterialeSantier from "../tabelDepozit";
import { useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../../utils/rapoarte/raportInventarPDF";
import { useNavigate } from "react-router-dom";
import { useDepozitContext } from "../context/AuthContext";
import { generateExcelFile } from "../rapoarte/raportXLSXDepozite";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { useAuth } from "../../../common/Login/AuthContext";

import { epochFormatDate } from "../../../../utils/formats/epochFormat";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styleRaport = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isNewDepozit, setIsNewDepozit } = useDepozitContext();
  const { setIsAutheticated } = useAuth();

  const [open2, setOpen2] = useState(false);
  const [triggerMutare, setTriggerMutare] = useState();
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);

  const [nume, setNume] = useState();
  const [locatie, setLocatie] = useState();

  const [santiers, setSantiers] = useState([]);

  const apiUrl = `https://jellyfish-app-f8wy8.ondigitalocean.app/api/agronomie/depozit/${id}`;
  const section = "depozit";

  const handleAccessDepozite = (item) => {
    navigate(`/agronomie/plantatie/${item._id}`);
  };

  function handleShowCurrentPlantatie(item) {
    const lastDepozitTata = item?.depozitTata[0];
    if (lastDepozitTata && data._id === lastDepozitTata) {
      return true;
    }
  }

  const createDepozit = async () => {
    const depozitData = {
      nume: nume,
      locatie: locatie,
      depozitTata: [id],
    };
    const apiUrl = "https://jellyfish-app-f8wy8.ondigitalocean.app/api/agronomie/santier";
    try {
      const response = await fetch(apiUrl, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(depozitData),
      });
      if (response.ok) {
        console.log("Supplier created successfully");
        setIsNewDepozit(depozitData);
        setOpen2(!open2);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setter(data);
      } else if (response.status === 401) {
        // Handle 401 Unauthorized
        alert("Access is forbidden Redirecting to login.");
        navigate("/login"); // Navigate to login page
      } else if (response.status === 403) {
        // Handle 403 Forbidden
        alert("Access is forbidden Redirecting to login.");
        navigate("/login"); // Navigate to login page
      } else {
        alert("Access is forbidden Redirecting to login.");
      }
    } catch (error) {
      alert(`Error fetching data from ${url}: ${error}`);
    }
  };

  useEffect(() => {
    fetchData("https://jellyfish-app-f8wy8.ondigitalocean.app/api/agronomie/santier", setSantiers);
  }, [open2, isNewDepozit, triggerMutare]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(apiUrl, {
          credentials: "include",
        });
        if (res.ok) {
          const data = await res.json();
          setData(data);
        } else if (res.status === 401 || res.status === 403) {
          // Token has expired or is invalid. Handle as needed, e.g., redirect to login.
          alert(
            "Session expired or unauthorized access. Redirecting to login."
          );

          navigate("/login");
        } else {
          console.error("Response not OK:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [id, apiUrl, isNewDepozit]); // Add `setData` and `history` to the dependency array if they are external to this useEffect.

  const dateDepozit = `${data?.nume} - ${data?.locatie}`;

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  const [openRaport, setOpenRaport] = useState(false);

  //-------------Delete dpozit---------

  async function deleteDepozit() {
    const dataMaterial = {
      materialeEliminate: data?.materialeDepozit,
    };

    await fetch(apiUrl, {
      credentials: "include",
      method: "DELETE",
      body: JSON.stringify(dataMaterial),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  const [openDelete, setOpenDelete] = useState(false);

  const handleDeleteDepozit = () => {
    setIsNewDepozit(data);
    setOpenDelete(false);
    deleteDepozit();
    navigate("/agronomie/materiale");
  };

  console.log(data);

  return (
    <div key={id}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: "40px" }}>
          <h3>
            {data?.nume} - {data?.locatie}
          </h3>
          <p style={{ fontSize: "20px" }}>
            Materiale: {data?.materialeDepozit?.length}
          </p>
        </div>
        <div>
          <Button
            variant="contained"
            sx={{
              bgcolor: "red.dark", // normal background color
              "&:hover": {
                bgcolor: "red.light", // hover background color
              },
            }}
            onClick={() => setOpenDelete(true)}
          >
            Elimina
          </Button>
        </div>
      </div>

      <MaterialeSantier
        depozitId={id}
        section={"depozit"}
        triggerPlantatie={open2}
        setTriggerMutare={setTriggerMutare}
      />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenRaport(true)}
          sx={{
            bgcolor: "blue.dark", // normal background color
            "&:hover": {
              bgcolor: "blue.light", // hover background color
            },
          }}
        >
          {" "}
          Genereaza raport
        </Button>
        <br />
        <Button
          variant="contained"
          onClick={() => setOpen2(true)}
          sx={{
            bgcolor: "blue.dark", // normal background color
            "&:hover": {
              bgcolor: "blue.light", // hover background color
            },
          }}
        >
          Creeaza Plantatie
        </Button>
      </div>

      <br />

      <div
        style={{
          maxHeight: "40vh",
          overflowY: "auto",
          overflowX: "auto",
          paddingLeft: "20px",
        }}
      >
        <table
          className="table table-striped"
          style={{ marginTop: 20, borderSpacing: "50px" }}
        >
          <thead>
            <tr>
              <th>Nume</th>
              <th>Locatie</th>
              <th>Materiale</th>
              <th>Actiuni</th>
              {/* Add additional header columns as needed */}
            </tr>
          </thead>
          <tbody>
            {santiers.map(
              (item) =>
                handleShowCurrentPlantatie(item) && (
                  <tr key={item._id}>
                    <td>{item.nume}</td>
                    <td>{item.locatie}</td>
                    <td>
                      {" "}
                      <Tooltip
                        title={
                          <List>
                            {item?.materialeSantier?.map((material) => (
                              <ListItem key={material.id}>
                                <Typography variant="h6">
                                  {material.cantitate}x {material.nume} -{" "}
                                  {material.numeFirma}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        }
                      >
                        {item?.materialeSantier?.length > 0 ? (
                          <div style={{ display: "flex" }}>
                            <span>Lista</span> <InfoIcon />
                          </div>
                        ) : (
                          "Nimic"
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "blue.dark", // normal background color
                          "&:hover": {
                            bgcolor: "blue.light", // hover background color
                          },
                        }}
                        onClick={() => handleAccessDepozite(item)}
                      >
                        Access
                      </Button>
                      {/* |
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleAccessDepozite(item)}
                        disabled
                      >
                        Elimina
                      </Button> */}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>

      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Plantatie</h2>
            <br />
            <TextField
              type="text"
              placeholder={`Nume plantatie`}
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder={`Locatie plantatie`}
              onChange={(e) => setLocatie(e.target.value)}
            ></TextField>
            <br />
            <br />

            <Button
              onClick={() => createDepozit()}
              variant="contained"
              color="success"
            >
              Adauga Plantatie
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openRaport}
        onClose={() => setOpenRaport(!openRaport)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleRaport}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Genereaza raport</h2>
            <br />
            <PDFDownloadLink
              document={
                <Factura
                  materiale={data?.materialeDepozit}
                  section={section}
                  data={data}
                  date={currentDate}
                />
              }
              fileName={`${"Raport "}${data.nume}${" "}${
                data.locatie
              }${".pdf"}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Generating PDF..."
                ) : (
                  <Button variant="contained" color="error">
                    Raport .PDF <PictureAsPdfIcon style={{ marginLeft: 12 }} />
                  </Button>
                )
              }
            </PDFDownloadLink>{" "}
            <br />
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  generateExcelFile(data, dateDepozit, currentDate)
                }
              >
                Raport .XLSX <ExplicitIcon style={{ marginLeft: 12 }} />
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>
              {data?.nume} - {data?.locatie}
            </b>
            Sigur inchideti depozit?
            <br />
            <br />
            <span style={{ color: "grey" }}>
              Inchizand depozit, materialele din depozit vor fi eliminate atat
              din depozit cat si din cantitate depozit si din stocul global din
              depozitul principal.
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                color="error"
                onClick={handleDeleteDepozit}
              >
                Inchide
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenDelete(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Record;
