import React, { useEffect, useState } from "react";
import OverviewSoferi from "./OverviewSoferi";

const SoferiContainer = () => {
  const [soferi, setSoferi] = useState([]);

  const [trigger, setTrigger] = useState();

  const [formData, setFormData] = useState({
    luna: "",
    sofer: "",
    masina: "",
    ajutorSofer: "",
    locParcare: "",
    sarcini: [],
    sosireKilometraj: "",
    sosireZiuaLuna: "",
    sosireOraMin: "",
    plecareKilometraj: "",
    plecareZiuaLuna: "",
    plecareOraMin: "",
    parcursRealizat: "",
    alimentare: "",
  });

  const handleInputChange = (key, value, index) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
        sarcini: prevFormData.sarcini.map((sarcina, i) =>
          i === index ? { ...sarcina, [key]: value } : sarcina
        ),
      };
    });
  };

  function getCurrentMonthYear() {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Month is zero-indexed, so we add 1
    const year = currentDate.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formattedMonth}-${year}`;
  }

  const currentWorkingMonth = getCurrentMonthYear();

  const [currentDriverId, setCurrentDriverId] = useState();

  const handleDriverId = (id) => setCurrentDriverId(id);

  const handleNewActivity = () => {
    const newData = Date.now();
    const data = {
      luna: currentWorkingMonth,
      sofer: formData.sofer,
      masina: formData.masina,
      ajutorSofer: formData.ajutorSofer,
      locParcare: formData.locParcare,
      sarcini: formData.sarcini,
      sosireKilometraj: formData.sosireKilometraj,
      sosireZiuaLuna: formData.sosireZiuaLuna,
      sosireOraMin: formData.sosireOraMin,
      plecareKilometraj: formData.plecareKilometraj,
      plecareZiuaLuna: formData.plecareZiuaLuna,
      plecareOraMin: formData.plecareOraMin,
      parcursRealizat: formData.parcursRealizat,
      alimentare: formData.alimentare,
    };

    console.log("req", data);

    fetch(
      `https://jellyfish-app-f8wy8.ondigitalocean.app/api/soferi/${currentDriverId}/createActivity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        setTrigger(data + newData);

        setFormData({
          luna: "",
          sofer: "",
          masina: "",
          ajutorSofer: "",
          locParcare: "",
          sarcini: [],
          sosireKilometraj: "",
          sosireZiuaLuna: "",
          sosireOraMin: "",
          plecareKilometraj: "",
          plecareZiuaLuna: "",
          plecareOraMin: "",
          parcursRealizat: "",
          alimentare: "",
        });

        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/soferi`)
      .then((response) => response.json())

      .then((data) => setSoferi(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [trigger]);

  const [formDataNewSofer, setFormDataNewSofer] = useState({
    nume: "",
    telefon: "",
  });

  const handleInputChangeNewSofer = (key, value) => {
    setFormDataNewSofer((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
      };
    });
  };

  const handleAddNewSofer = async () => {
    const dataSoferi = {
      nume: formDataNewSofer.nume,
      telefon: formDataNewSofer.telefon,
    };

    fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/soferi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataSoferi),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setTrigger(dataSoferi);
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setFormDataNewSofer({
          nume: "",
          telefon: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [formDataAlimentare, setFormDataAlimentare] = useState({
    benzinarie: "",
    cantitate: "",
    suma: "",
    dataAlimentare: "",
  });

  const handleInputChangeNewAlimentare = (key, value) => {
    setFormDataAlimentare((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
      };
    });
  };

  const handleAddNewAlimentare = async () => {
    const dataAlimentare = {
      benzinarie: formDataAlimentare.benzinarie,
      cantitate: formDataAlimentare.cantitate,
      suma: formDataAlimentare.suma,
      dataAlimentare: formDataAlimentare.dataAlimentare,
    };

    fetch(
      `https://jellyfish-app-f8wy8.ondigitalocean.app/api/soferi/${currentDriverId}/createAlimentare`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataAlimentare),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setFormDataNewSofer({
          benzinarie: "",
          cantitate: "",
          suma: "",
          dataAlimentare: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <OverviewSoferi
        key={trigger}
        dateSoferiBulk={soferi}
        {...{
          handleNewActivity,
          handleInputChange,
          formData,
          setFormData,
          handleAddNewSofer,
          handleInputChangeNewSofer,
          handleInputChangeNewAlimentare,
          handleAddNewAlimentare,
          handleDriverId,
          trigger,
        }}
      />
    </div>
  );
};

export default SoferiContainer;
