import { createTheme } from "@mui/material/styles";

const getTheme = (mode) =>
  createTheme({
    palette: {
      type: mode,
      blue: {
        main: mode === "light" ? "#598BAF" : "#345975", // A more vivid blue, brighter in light mode, slightly subdued in dark mode
        light: mode === "light" ? "#598BAF" : "#507DA0", // A lighter, more vibrant blue for both modes
        dark: mode === "dark" ? "#234E6C" : "#2C3E50", // A deeper blue for contrast and depth in dark mode
        focus: "#2C3E50", // A strong, attention-grabbing blue for focused elements
        focusHover: "#598BAF", // An even lighter blue for hover states, enhancing interactivity
        contrastText: "#D9DCE1", // White for maximum readability against the blues
      },
      green: {
        main: mode === "light" ? "#43A047" : "#388E3C", // A lively green that stands out against the vivid blue
        light: "#2E7D32", // A fresh, light green for a pop of vibrant color
        dark: "#66BB6A", // A darker green for depth and contrast, especially in dark mode
      },
      red: {
        main: "#E53935", // A vivid red to provide a strong contrast with the blues and yellows
        light: "#EF5350", // A lighter, vibrant red for highlighting important elements
        dark: "#C62828", // A darker red for a more intense contrast in dark mode
      },
      grey: {
        main: mode === "light" ? "#9E9E9E" : "#B0BEC5", // Neutral grey for both modes
        light: mode === "light" ? "#E0E0E0" : "#CFD8DC", // Light grey for both modes
        dark: mode === "dark" ? "#757575" : "#616161",
      },
    },

    // You can also define other theme properties here
    // ...
  });

// Export the light and dark themes
export const lightTheme = getTheme("light");
export const darkTheme = getTheme("dark");
