import React, { useState, useEffect } from "react";
import ClientiView from "./ClientiView";

const ClientiContainer = () => {
  const [clientiList, setClientiList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [trigger, setTrigger] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/clienti",
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        setClientiList(data);
        console.log("to", response);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching clienti:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, [trigger]);

  return (
    <div>
      {loading ? (
        // Display loading indicator or message while data is being fetched
        <p>Loading...</p>
      ) : (
        // Render ClientiView with the fetched data
        <ClientiView clienti={clientiList} setTrigger={setTrigger} />
      )}
    </div>
  );
};

export default ClientiContainer;
