export function convertTime(inputDate) {
  const [month, year] = inputDate.split("-");
  const monthNames = [
    "Januuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Junie",
    "Julie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];

  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${monthName} - ${year}`;
}
