import { furnizori } from "../../../testData/furnizori";
import Record from "../furnizori/furnizor";
import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NouaLista = () => {
  const [data, setData] = useState([]);
  const [nume, setNume] = useState();
  const [open, setOpen] = useState(false);
  const currentData = furnizori;

  const createSupplier = async () => {
    const supplierData = {
      nume,
    };

    try {
      const response = await fetch(
        `https://modvin-erb-tyu-4e6087587d89.herokuapp.com/api/suppliers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        setOpen(!open);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://modvin-erb-tyu-4e6087587d89.herokuapp.com/api/suppliers"
        );
        if (res.ok) {
          const data = await res.json();
          setData(data); // Update the state with fetched data
        } else {
          console.error("Response not OK:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData(); // Call the function to fetch data
  }, [createSupplier]);

  function recordList() {
    return data.map((record, index) => <Record key={index} record={record} />);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <h3>Lista Furnizori</h3>
          <Button variant="contained" onClick={() => setOpen(!open)}>
            Creaza Furnizor
          </Button>
        </div>

        <table
          className="table table-striped"
          style={{ marginTop: 20, borderSpacing: "50px" }}
        >
          <thead>
            <tr>
              <th>Nume</th>
              <th>Actiuni</th>
            </tr>
          </thead>
          <tbody>{recordList()}</tbody>
        </table>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Furnizor Nou</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume Furnizor"
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <Button onClick={() => createSupplier()}> Adauga Furnizor</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NouaLista;
