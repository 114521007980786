import React from "react";
import BarChart from "./BarChart";
import MaterialUsageLineChart from "./LineChart";

const StatisticsPage = () => {
  return (
    <div>
      <div style={{ width: "90%", height: "100%" }}>
        <BarChart />
      </div>
    </div>
  );
};

export default StatisticsPage;
