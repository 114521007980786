import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const MaterialModal = ({
  openMaterial,
  handleModalMaterialClose,
  setNume,
  setNumeFirma,
  setModel,
  setNumarInventar,
  updateForm,
  formEdit,
  uniqueCategories,
  setNewCategory,
  newCategory,
  dataExpirare,
  setDataExpirare,
  stoc,
  handleInputStoc,
  createMaterial,
  styleInv,
  updateFormData,
  formData,
}) => {
  return (
    <Modal
      open={openMaterial}
      onClose={handleModalMaterialClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleInv}>
        <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
          <div>
            <h3>Adauga produs</h3>
            <br />
            <div
              style={{ gap: "4px", display: "flex", flexDirection: "column" }}
            >
              <TextField
                label="Producator"
                onChange={(e) => updateFormData({ producator: e.target.value })}
              />
              <TextField
                label="Model"
                onChange={(e) => updateFormData({ model: e.target.value })}
              />

              <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
              <Select
                label="Categorie"
                value={formData.categorie}
                onChange={(e) => {
                  updateFormData({ categorie: e.target.value });
                  setNewCategory(""); // Reset the new category input when changing the selection
                }}
              >
                {uniqueCategories.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                <MenuItem value={"adauga"}>Adauga Categorie Noua</MenuItem>
              </Select>

              {formData.categorie === "adauga" && (
                <TextField
                  type="text"
                  placeholder="Categorie"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              )}
              <TextField
                label="Numar Inventar"
                onChange={(e) =>
                  updateFormData({ numarInventar: e.target.value })
                }
              />

              <TextField
                label="Serie S/N"
                onChange={(e) => updateFormData({ serieSN: e.target.value })}
              />
              <TextField
                label="Stoc"
                onChange={(e) => updateFormData({ stoc: e.target.value })}
              />
              <TextField
                label="Pret Achizitie"
                onChange={(e) => updateFormData({ pretA: e.target.value })}
              />
              <TextField
                label="Pret Vanzare"
                onChange={(e) => updateFormData({ pretV: e.target.value })}
              />

              <TextField
                label="TVA"
                onChange={(e) => updateFormData({ tva: e.target.value })}
              />
            </div>
          </div>
          <br />
          <br />
          <Button
            variant="contained"
            sx={{ bgcolor: "green.light" }}
            onClick={() => createMaterial()}
          >
            Adauga produs
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default MaterialModal;
