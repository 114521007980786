import {
  Box,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const UserManagementView = ({ users, setTrigger }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const [form, setForm] = useState({});

  const [openDelete, setOpenDelete] = useState(false);

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  const handleRoleText = () => {
    switch (form.role) {
      case "standard":
        return "Only read rights.";
      case "moderator":
        return "Read and write rights.";
      case "admin":
        return "Full rights. (read, write, delete, add)";
      // Add more cases if needed
      default:
        return ""; // Default case, return an empty string or another default value
    }
  };

  const handleEditForm = (item) => {
    setForm(item);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setForm({});
    setOpenEdit(false);
  };

  async function updateUser(e) {
    e.preventDefault();

    const editedItem = {
      name: form.name,
      email: form.email,
      role: form.role,
      isAdmin: form.role === "admin",
    };

    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/users/${form._id}`, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(editedItem),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setOpenEdit(false);
    setTrigger(form?._id);
  }

  async function deleteUser(e) {
    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/users/${form._id}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    setOpenDelete(false);
    setTrigger(form?._id);
  }

  return (
    <div style={{ padding: 12 }}>
      <h3>User Management</h3>
      <table
        className="table table-striped"
        style={{ marginTop: 20, borderSpacing: "50px" }}
      >
        <thead>
          <tr>
            <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>Nume</th>
            <th style={{ paddingRight: "40px" }}>Email</th>
            <th style={{ paddingRight: "40px" }}>Role</th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item) => (
            <tr key={item._id}>
              <td
                style={{
                  paddingRight: "40px",
                  paddingLeft: "30px",
                }}
              >
                {item.name}
              </td>
              <td style={{ paddingRight: "40px" }}>{item.email}</td>
              <td style={{ paddingRight: "40px" }}>{item.role}</td>
              <td>
                <Button
                  variant="contained"
                  onClick={() => handleEditForm(item)}
                  sx={{
                    bgcolor: "blue.dark", // normal background color
                    "&:hover": {
                      bgcolor: "blue.light", // hover background color
                    },
                  }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={openEdit} onClose={handleClose}>
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 28 }}>
              <b>Modifica user:</b> {form.name}
            </span>
            <br />
            <TextField
              type="text"
              placeholder="Nume"
              value={form.name}
              onChange={(e) => updateForm({ name: e.target.value })}
            ></TextField>
            <TextField
              type="text"
              placeholder="Email"
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value })}
            ></TextField>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.role}
              label="Role"
              onChange={(e) => updateForm({ role: e.target.value })}
            >
              <MenuItem value={"standard"}>Standard</MenuItem>
              <MenuItem value={"moderator"}>Moderator</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
            </Select>
            <FormHelperText>{handleRoleText()}</FormHelperText>

            <br />
            <Button
              variant="contained"
              onClick={updateUser}
              sx={{
                bgcolor: "green.dark", // normal background color
                "&:hover": {
                  bgcolor: "green.light", // hover background color
                },
              }}
            >
              Actualizeaza
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 8,
              }}
            >
              <Button
                variant="contained"
                onClick={() => setOpenDelete(true)}
                sx={{
                  bgcolor: "red.dark", // normal background color
                  "&:hover": {
                    bgcolor: "red.light", // hover background color
                  },
                }}
              >
                Elimina
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>Sigur eliminati user?</b>
            {form?.name}
            <br />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                color="error"
                onClick={() => deleteUser()}
              >
                Elimina
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenDelete(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserManagementView;
