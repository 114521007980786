import React from "react";
import { Modal, Box, Button } from "@mui/material";

const DeleteMaterialModal = ({
  openDelete,
  setOpenDelete,
  formEdit,
  handleDeleteMaterial,
  styleInv,
}) => {
  return (
    <Modal
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleInv}>
        <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
          <b>{formEdit?.nume}</b>
          Sigur stergeti materialul?
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginTop: "8px", marginRight: "12px" }}
              variant="contained"
              color="error"
              sx={{ bgcolor: "red.light" }}
              onClick={handleDeleteMaterial}
            >
              Da
            </Button>

            <br />

            <Button
              style={{ marginTop: "8px" }}
              variant="contained"
              onClick={() => setOpenDelete(false)}
            >
              Nu
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteMaterialModal;
