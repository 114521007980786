import React, { useState } from "react";
import { List, ListItem, ListItemText, Collapse, Button } from "@mui/material";

import DetaliiLuna from "./DetaliiLuna";
import FoaieParcurs from "../Modals/FoaieParcurs";
import GenereazaRaport from "../Modals/GenereazaRaport";

import { convertTime } from "../../../../utils/convertTime";

const CursaComponent = ({
  handleNewActivity,
  handleInputChange,
  formData,
  selectedDriver,
  setFormData,
  trigger,
}) => {
  const [openMonthIndices, setOpenMonthIndices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    setOpenRaport(false);
  };

  const handleOpenRaport = () => setOpenRaport(true);
  const [openRaport, setOpenRaport] = React.useState(false);

  const toggleMonth = (index) => {
    setOpenMonthIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <List
          key={trigger}
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "4px",
            width: "85%",
          }}
        >
          <ListItem>
            <ListItemText primary="Activitate:" />
          </ListItem>
          {selectedDriver?.curse?.map((month, monthIndex) => (
            <div key={monthIndex}>
              <ListItem button onClick={() => toggleMonth(monthIndex)}>
                <ListItemText primary={<b>{convertTime(month?.luna)}</b>} />
              </ListItem>
              <Collapse in={openMonthIndices.includes(monthIndex)}>
                <div>
                  {month?.zileLucrate?.map((route, routeIndex) => (
                    <DetaliiLuna
                      {...{
                        route,
                        routeIndex,
                        selectedDriver,
                        month,
                        monthIndex,
                      }}
                      key={routeIndex}
                    />
                  ))}
                </div>
              </Collapse>
            </div>
          ))}
        </List>
        <div
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "4px",
            padding: "10px",
            display: "flex",
            flexDirection: "column",

            gap: "8px",
          }}
        >
          <Button variant="contained" onClick={handleOpen}>
            Adauga Activitate
          </Button>
          <Button variant="contained" onClick={handleOpenRaport}>
            Genereaza Raport
          </Button>
        </div>
      </div>
      {selectedDriver && (
        <FoaieParcurs
          {...{
            open,
            handleClose,
            selectedDriver,
            handleInputChange,
            edit,
            handleNewActivity,
            formData,
            setFormData,
          }}
        />
      )}

      <GenereazaRaport
        {...{
          openRaport,
          handleClose,
          selectedDriver,
          handleInputChange,
          edit,
          handleNewActivity,
          formData,
          setFormData,
        }}
      />
    </>
  );
};

export default CursaComponent;
