import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const generateExcelFile = (materiale, santier, date) => {
  const includedFields = [
    "nume",
    "numeFirma",
    "model",
    "categorie",
    "cantitate",
  ];

  // Filter the data to include only the specified fields
  const filteredData = materiale.map((item) => {
    const filteredItem = {};
    includedFields.forEach((field) => {
      filteredItem[field] = item[field];
    });
    return filteredItem;
  });

  const ws = XLSX.utils.json_to_sheet(filteredData);

  // Set background color for the first row
  ws["!cols"] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];
  ws["!rows"] = [
    {
      hidden: false,
      customHeight: false,
      outlineLevel: 0,
      collapsed: false,
      level: 0,
      thickTop: false,
      ph: true,
    },
  ];

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `raport-${santier}-${date}.xlsx`);
};
