
import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const EditMaterialModal = ({
  openEdit,
  setOpenEdit,
  formEdit,
  updateForm,
  uniqueCategories,
  setNewCategory,
  newCategory,
  handleCloseEdit,
  setOpenDelete,
  userData,
  formatCustomDateString, // Assuming this is a function passed as a prop
  styleInv,
}) => {
  return (
    <Modal
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleInv}>
        <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>Editeaza produs: {formEdit.model}</h2>
            <Tooltip
              title={
                <span style={{ fontSize: 14 }}>
                  Editat de {formEdit.author} in{" "}
                  {formatCustomDateString(formEdit.updatedAt)}
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <br />
          <InputLabel>Producator</InputLabel>
          <TextField
            type="text"
            placeholder="Producator"
            value={formEdit.producator}
            onChange={(e) => updateForm({ producator: e.target.value })}
          />
          <InputLabel>Model</InputLabel>
          <TextField
            type="text"
            placeholder="Firma material"
            value={formEdit.model}
            onChange={(e) => updateForm({ model: e.target.value })}
          />

          <InputLabel>Categorie</InputLabel>
          <Select
            value={formEdit.categorie}
            onChange={(e) => {
              updateForm({ categorie: e.target.value });
              setNewCategory("");
            }}
          >
            {uniqueCategories.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
            <MenuItem value="adauga">Adauga Categorie Noua</MenuItem>
          </Select>
          {formEdit.categorie === "adauga" && (
            <TextField
              type="text"
              placeholder="Categorie"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          )}
          <InputLabel>Numar Inventar</InputLabel>
          <TextField
            type="text"
            placeholder="Numar Inventar"
            value={formEdit.numarInventar}
            onChange={(e) => updateForm({ numarInventar: e.target.value })}
          />
          <InputLabel>Serie S/N</InputLabel>
          <TextField
            type="text"
            placeholder="Serie S/N"
            value={formEdit.serieSN}
            onChange={(e) => updateForm({ serieSN: e.target.value })}
          />
          <InputLabel>Stoc</InputLabel>
          <TextField
            type="text"
            placeholder="Stoc"
            value={formEdit.stoc}
            onChange={(e) => updateForm({ stoc: e.target.value })}
          />

<InputLabel>Pret Achizitie</InputLabel>
          <TextField
            type="text"
            placeholder="Pret Achizitie"
            value={formEdit.pretA}
            onChange={(e) => updateForm({ pretA: e.target.value })}
          />

<InputLabel>Pret Vanzare</InputLabel>
          <TextField
            type="text"
            placeholder="Pret Vanzare"
            value={formEdit.pretV}
            onChange={(e) => updateForm({ pretV: e.target.value })}
          />
                    <InputLabel>TVA</InputLabel>
          <TextField
            type="text"
            placeholder="TVA"
            value={formEdit.tva}
            onChange={(e) => updateForm({ tva: e.target.value })}
          />
          <br />
          <br/>
          <Button
            variant="contained"
            sx={{
              bgcolor: "green.light",
              "&:hover": { bgcolor: "green.dark" },
            }}
            onClick={handleCloseEdit}
          >
            Actualizare
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "8px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              disabled={!userData.isAdmin}
              sx={{ bgcolor: "red.dark", "&:hover": { bgcolor: "red.light" } }}
              onClick={() => setOpenDelete(true)}
            >
              Elimina
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditMaterialModal;
