import { Modal, Box, Button } from "@mui/material";
import React from "react";

const Record = ({ avans }) => {
  return (
    <tr>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "450px",
            marginLeft: "200px",
          }}
        >
          <p>{avans.nume}</p>

          <p>{`${avans.suma.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Euro`}</p>

          <p>{avans.data}</p>
        </div>
      </td>
      <td>
        <Button>Editeaza Avans</Button>
      </td>
    </tr>
  );
};

export default Record;
