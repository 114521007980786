import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  Modal,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import ReactDOM from "react-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Depozit1 = () => {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <defs>
        <style>
          {`.cls-1, .cls-2 { fill: none; stroke: #000000; stroke-linejoin: round; stroke-width: 2px; }
                      .cls-1 { stroke-linecap: round; }`}
        </style>
      </defs>
      <g id="deposit">
        <circle className="cls-1" cx="46" cy="10" r="2" />
        <circle cx="17.0992" cy="9.5232" r="1.0691" />
        <line
          className="cls-1"
          x1="52.5509"
          x2="55.5509"
          y1="14.0547"
          y2="17.0547"
        />
        <line
          className="cls-1"
          x1="55.5509"
          x2="52.5509"
          y1="14.0547"
          y2="17.0547"
        />
        <polyline className="cls-1" points="7 30.169 7 59 55 59 55 30.217" />
        <path
          className="cls-1"
          d="M54.998,30.0005a1.9909,1.9909,0,0,1-1.0581-.3047L31,15.3584,8.06,29.6958a2,2,0,0,1-2.12-3.3916l24-15a2.0005,2.0005,0,0,1,2.12,0l24,15a2,2,0,0,1-1.0621,3.6963Z"
        />
        <polyline className="cls-1" points="17 58.808 17 49 25.84 49" />
        <rect className="cls-1" height="10" width="4" x="40" y="49" />
        <polyline className="cls-2" points="49 59 49 32 13 32 13 59" />
        <rect className="cls-1" height="7" width="18" x="22" y="52" />
        <polyline className="cls-2" points="26 52 26 45 36 45 36 52" />
        <line className="cls-2" x1="13" x2="49" y1="36" y2="36" />
        <line className="cls-2" x1="13" x2="49" y1="40" y2="40" />
        <line className="cls-2" x1="31" x2="31" y1="59" y2="52" />
      </g>
    </svg>
  );
};

const Depozit2 = () => {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <defs>
        <style>
          {`.cls-1, .cls-2 { fill: none; stroke: #000000; strokeLinejoin: round; strokeWidth: 2px; }
                      .cls-1 { strokeLinecap: round; }`}
        </style>
      </defs>
      <g>
        <circle className="cls-1" cx="51.5" cy="9.5" r="2.5" />
        <path d="M49.6324,18.5A1.1324,1.1324,0,1,1,48.5,17.3676,1.1324,1.1324,0,0,1,49.6324,18.5Z" />
        <line
          className="cls-1"
          x1="15.9355"
          x2="12.9874"
          y1="8.5978"
          y2="11.5459"
        />
        <line
          className="cls-1"
          x1="12.9874"
          x2="15.9355"
          y1="8.5978"
          y2="11.5459"
        />
        <polyline className="cls-2" points="23.994 50 6 50 6 35" />
        <line className="cls-2" x1="26" x2="26" y1="35" y2="45.6216" />
        <rect className="cls-1" height="4" width="24" x="4" y="31" />
        <ellipse
          className="cls-1"
          cx="20"
          cy="28"
          rx="4"
          ry="2"
          transform="translate(-12.8472 17.7501) rotate(-37.1319)"
        />
        <ellipse
          className="cls-1"
          cx="12"
          cy="28"
          rx="2"
          ry="4"
          transform="translate(-17.5668 20.6647) rotate(-52.8681)"
        />
        <line className="cls-1" x1="32" x2="32" y1="15" y2="19" />
        <line className="cls-1" x1="30" x2="34" y1="17" y2="17" />
        <polyline className="cls-1" points="24 14 26 13 26 21" />
        <polyline className="cls-1" points="37 14 39 13 39 21" />
        <circle className="cls-1" cx="32" cy="51" r="8" />
        <polyline className="cls-1" points="30 52 32 54 34 48" />
        <line className="cls-2" x1="18" x2="18" y1="31" y2="50" />
        <line className="cls-2" x1="14" x2="14" y1="31" y2="50" />
        <line className="cls-2" x1="38" x2="38" y1="45.6691" y2="35" />
        <polyline className="cls-2" points="58 35 58 50 39.982 50" />
        <rect className="cls-1" height="4" width="24" x="36" y="31" />
        <ellipse
          className="cls-1"
          cx="52"
          cy="28"
          rx="4"
          ry="2"
          transform="translate(-6.3591 37.067) rotate(-37.1319)"
        />
        <ellipse
          className="cls-1"
          cx="44"
          cy="28"
          rx="2"
          ry="4"
          transform="translate(-4.8836 46.1766) rotate(-52.8681)"
        />
        <line className="cls-2" x1="50" x2="50" y1="31" y2="50" />
        <line className="cls-2" x1="46" x2="46" y1="31" y2="50" />
      </g>
    </svg>
  );
};

const InteractiveSVG = ({ imageUrl }) => {
  const svgRef = useRef();
  const [buttonsData, setButtonsData] = useState(
    JSON.parse(localStorage.getItem("svgData")) || []
  );
  const [editMode, setEditMode] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState("type1");
  const [inputData, setInputData] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const [modalInputData, setModalInputData] = useState(""); // Local state for modal input

  const updateLocalStorage = (data) => {
    localStorage.setItem("svgData", JSON.stringify(data));
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear SVG on re-render

    svg
      .append("image")
      .attr("href", imageUrl)
      .attr("width", "100%")
      .attr("height", "100%");

    buttonsData.forEach((d) => {
      const container = svg
        .append("g")
        .attr("transform", `translate(${d.x}, ${d.y})`);

      if (d.type === "type1") {
        ReactDOM.render(<Depozit1 />, container.node());
      } else if (d.type === "type2") {
        ReactDOM.render(<Depozit2 />, container.node());
      }
      // Add more types as needed

      container.on("click", (event) => {
        if (editMode) {
          event.stopPropagation();
          setSelectedElement(d);
          setSelectedType(d.type);
          setInputData(d.data);
          setShowModal(true);
        } else {
          setTooltipPosition({ x: event.clientX, y: event.clientY });
          setSelectedElement(d);
          setShowTooltip(true);
        }
      });
    });

    if (editMode) {
      svg.on("click", function (event) {
        const coords = d3.pointer(event);
        const newElement = {
          id: Date.now(),
          x: coords[0],
          y: coords[1],
          type: selectedType,
          data: inputData,
        };
        const updatedData = [...buttonsData, newElement];
        setButtonsData(updatedData);
        updateLocalStorage(updatedData);
      });
    } else {
      svg.on("click", null);
    }
  }, [imageUrl, editMode, buttonsData, selectedType, inputData]);

  const saveSVGData = async () => {
    updateLocalStorage(buttonsData); // Update localStorage after removing an element
    console.log("Save SVG functionality not implemented.");
  };

  const handleRemoveElement = () => {
    const updatedData = buttonsData.filter((item) => item !== selectedElement);
    setButtonsData(updatedData);
    updateLocalStorage(updatedData); // Update localStorage after removing an element
    setShowModal(false);
  };

  const handleChangeElement = () => {
    const updatedData = buttonsData.map((item) => {
      if (item.id === selectedElement.id) {
        return { ...item, type: selectedType, data: modalInputData };
      }
      return item;
    });
    setButtonsData(updatedData);
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal && selectedElement) {
      setModalInputData(selectedElement.data);
    }
  }, [showModal, selectedElement]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Modal Content
  const ModalContent = () => (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit SVG Element
        </Typography>
        <FormControl fullWidth style={{ marginTop: "10px" }}>
          <InputLabel id="demo-simple-select-label">SVG Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedType}
            label="SVG Type"
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <MenuItem value="type1">Type 1</MenuItem>
            <MenuItem value="type2">Type 2</MenuItem>
            <MenuItem value="type3">Type 3</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Data for Tooltip"
          value={modalInputData}
          onChange={(e) => setModalInputData(e.target.value)}
          fullWidth
          style={{ marginTop: "10px" }}
        />
        <Button
          variant="contained"
          onClick={handleChangeElement}
          style={{ marginTop: "20px" }}
        >
          Change Element
        </Button>
        <Button
          variant="contained"
          onClick={handleRemoveElement}
          style={{ marginTop: "20px" }}
        >
          Remove Element
        </Button>
      </Box>
    </Modal>
  );

  return (
    <div style={{ marginTop: "50px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <svg ref={svgRef} width={1000} height={380}></svg>
        {showTooltip && (
          <Tooltip
            open={showTooltip}
            title={selectedElement.data}
            placement="top"
            onClose={() => setShowTooltip(false)}
            style={{
              position: "absolute",
              left: tooltipPosition.x,
              top: tooltipPosition.y,
            }}
          >
            <div></div> {/* Empty div for tooltip anchor */}
          </Tooltip>
        )}
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 4 }}
        >
          <Button
            variant="contained"
            onClick={saveSVGData}
            sx={{
              bgcolor: "blue.focus", // normal background color
              "&:hover": {
                bgcolor: "blue.focusHover", // hover background color
              },
            }}
          >
            Save SVG Data
          </Button>
          <Button
            variant="contained"
            onClick={toggleEditMode}
            style={{ marginLeft: "10px" }}
            sx={{
              bgcolor: "blue.focus", // normal background color
              "&:hover": {
                bgcolor: "blue.focusHover", // hover background color
              },
            }}
          >
            {editMode ? "Stop Editing" : "Edit Map"}
          </Button>
        </div>
      </div>
      <ModalContent />
    </div>
  );
};

export default InteractiveSVG;
