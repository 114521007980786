import { clienti } from "../../../testData/clienti";
import Record from "./furnizor";

const NouaLista = () => {
  const currentData = clienti;

  function recordList() {
    return currentData.map((record) => <Record record={record} />);
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <h3>Lista Clienti</h3>

      <table
        className="table table-striped"
        style={{ marginTop: 20, borderSpacing: "50px" }}
      >
        <thead>
          <tr>
            <th>Nume</th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody>{recordList()}</tbody>
      </table>
    </div>
  );
};

export default NouaLista;
