export const epochFormatDate = (epochTime) => {
  if (epochTime === null || epochTime === undefined) {
    return ""; // Return an empty string
  }

  let now = Date.now();

  const transformed = Number(epochTime);
  // Create a Date object from the epoch time (assumes epochTime is in milliseconds)
  const date = new Date(transformed);

  // Extract the day, month (0-based index), and year from the date
  const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits and correct for 0-based index
  const year = date.getFullYear();

  if (transformed <= now) {
    return <p style={{ color: "red" }}>{`${day}-${month}-${year}`}</p>;
  }

  const currentDate = new Date();

  // Add 7 days to the current date
  const sevenDaysLater = new Date(
    currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
  );

  if (transformed <= sevenDaysLater) {
    return <p style={{ color: "#C4B454" }}>{`${day}-${month}-${year}`}</p>;
  }

  // Format and return the date string in DD-MM-YYYY format
  return `${day}-${month}-${year}`;
};
