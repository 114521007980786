import React, { useEffect, useState } from "react";
import OverviewMasini from "./OverviewMasini";

const MasiniContainer = () => {
  const [masini, setMasini] = useState([]);
  const [masini2, setMasini2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.cargotrack.ro/atlas/apicargo/positions?password=115533"
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setMasini(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await fetch(
        "https://api.cargotrack.ro/atlas/apicargo/devices?password=115533"
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setMasini2(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const onLoad = async () => {
    try {
      setIsLoading(true);
      await Promise.all([fetchData(), fetchData2()]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  return (
    <OverviewMasini
      key={`${masini.length} - ${masini2.length}`}
      dateMasini={masini}
      masini2={masini2}
      isLoading={isLoading}
    />
  );
};

export default MasiniContainer;
