import React, { useState, useEffect } from "react";
import { Button, Modal, Box, Typography, TextField } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const stylePatrat = {
  display: "flex",

  border: "1px solid black",
  flexDirection: "column",
  padding: "10px",
};

const FoaieParcurs = ({
  open,
  handleClose,
  selectedDriver,
  handleInputChange,
  handleNewActivity,
  formData,
  setFormData,
}) => {
  const [openSarcina, setOpenSarcina] = useState(false);
  const handleAddSarcini = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: selectedDriver._id,
      sarcini: [...prevFormData.sarcini, {}],
    }));
  };

  const handleLocalActivity = () => {
    handleNewActivity();
    handleClose();
  };

  const handleNewSarcini = () => {
    setOpenSarcina(true);
  };

  const plataCheck = (check) => {
    if (check) {
      return (
        <div>
          <CheckIcon color="success" fontSize="small" />
        </div>
      );
    } else {
      return (
        <div>
          <CloseIcon color="error" fontSize="small" />
        </div>
      );
    }
  };

  const [newSarcina, setNewSarcina] = useState({
    expeditor: "",
    destinatiaExpA: "",
    destinatiaExpB: "",
    modPlata: "",
    platit: "",
    suma: "",
  });

  const handleNewSarcinaChange = (field, value) => {
    setNewSarcina((prev) => ({ ...prev, [field]: value }));
  };

  const addNewSarcinaToFormData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sarcini: [...prevFormData.sarcini, newSarcina],
    }));
    // Clear the newSarcina state and close the modal
    setNewSarcina({
      expeditor: "",
      destinatiaExpA: "",
      destinatiaExpB: "",
      modPlata: "",
      platit: "",
      suma: "",
    });
    setOpenSarcina(false);

    console.log("done", formData);
  };

  useEffect(() => {
    if (selectedDriver && selectedDriver.nume) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sofer: selectedDriver.nume,
      }));
    }
  }, [selectedDriver]);

  const handleRemoveSarcina = (indexToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sarcini: prevFormData.sarcini.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Adauga activitate: {selectedDriver?.nume}
            </Typography>
            <Button variant="contained" onClick={handleLocalActivity}>
              Adauga Activitate
            </Button>
          </div>
          <br />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <div style={stylePatrat}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  1. Codul unitatii de transport
                </Typography>
                <br />
                <div>
                  <TextField
                    label="Sofer"
                    value={
                      formData?.sofer ? formData?.sofer : selectedDriver?.nume
                    }
                    onChange={(e) => handleInputChange("sofer", e.target.value)}
                  />
                  <TextField
                    label="Nr inmatriculare autovehicul"
                    onChange={(e) =>
                      handleInputChange("masina", e.target.value)
                    }
                  />
                </div>

                <div>
                  <TextField
                    label="Ajutor"
                    onChange={(e) =>
                      handleInputChange("ajutorSofer", e.target.value)
                    }
                  />
                  <TextField
                    label="Loc parcare"
                    onChange={(e) =>
                      handleInputChange("locParcare", e.target.value)
                    }
                  />
                </div>
              </div>

              <div style={stylePatrat}>
                <div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    3. Sarcini de transport
                  </Typography>

                  {formData?.sarcini?.map((sarcina, index) => (
                    <div key={index} style={{ paddingBottom: 8 }}>
                      <div
                        style={{
                          padding: 8,
                          border: "1px solid #D9DCE1",
                          borderRadius: 8,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div> {sarcina?.expeditor} </div>
                        <div> {sarcina?.destinatiaExpA} </div>
                        <div> {sarcina?.destinatiaExpB}</div>
                        <div> {sarcina?.modPlata}</div>
                        <div> {plataCheck(sarcina?.platit)}</div>
                        <div> {sarcina?.suma}</div>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "grey.dark",
                            color: "white", // normal background color
                            "&:hover": {
                              bgcolor: "grey.light",
                              color: "black", // hover background color
                            },
                          }}
                          onClick={() => handleRemoveSarcina(index)}
                        >
                          Sterge
                        </Button>
                      </div>
                    </div>
                  ))}

                  {/* <br />
                  <Button onClick={handleAddSarcini} variant="contained">
                    Adauga Sarcina
                  </Button> */}

                  <Button onClick={handleNewSarcini} variant="contained">
                    Adauga Sarcini
                  </Button>

                  <br />
                </div>
              </div>

              <div style={stylePatrat}>
                <div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    5. Confirmarea starii tehnice
                  </Typography>
                  <br />
                  <div>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      La sosire
                    </Typography>
                    <TextField
                      label="Kilometraj"
                      onChange={(e) =>
                        handleInputChange("sosireKilometraj", e.target.value)
                      }
                    />
                    <TextField
                      label="Ziua - Luna"
                      onChange={(e) =>
                        handleInputChange("sosireZiuaLuna", e.target.value)
                      }
                    />
                    <TextField
                      label="Ora - Min"
                      onChange={(e) =>
                        handleInputChange("sosireOraMin", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      La plecare
                    </Typography>
                    <TextField
                      label="Kilometraj"
                      onChange={(e) =>
                        handleInputChange("plecareKilometraj", e.target.value)
                      }
                    />
                    <TextField
                      label="Ziua - Luna"
                      onChange={(e) =>
                        handleInputChange("plecareZiuaLuna", e.target.value)
                      }
                    />
                    <TextField
                      label="Ora - Min"
                      onChange={(e) =>
                        handleInputChange("plecareOraMin", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <TextField
                    label="Parcurs realizat (km)"
                    onChange={(e) =>
                      handleInputChange("parcursRealizat", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openSarcina}
        onClose={() => setOpenSarcina(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3>Activitate:</h3>
            <br />
            <div
              style={{ gap: "4px", display: "flex", flexDirection: "column" }}
            >
              <TextField
                label="Expeditor"
                onChange={(e) =>
                  handleNewSarcinaChange("expeditor", e.target.value)
                }
              />
              <TextField
                label="Locatie plecare"
                onChange={(e) =>
                  handleNewSarcinaChange("destinatiaExpA", e.target.value)
                }
              />
              <TextField
                label="Locatie sosire"
                onChange={(e) =>
                  handleNewSarcinaChange("destinatiaExpB", e.target.value)
                }
              />
              <InputLabel id="mod-plata-label">Mod plata</InputLabel>
              <Select
                labelId="mod-plata-label"
                value={newSarcina.modPlata}
                label="Mod plata"
                onChange={(e) =>
                  handleNewSarcinaChange("modPlata", e.target.value)
                }
              >
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Factura">Factura</MenuItem>
                <MenuItem value="Chitanta">Chitanta</MenuItem>
                <MenuItem value="Altele">Altele</MenuItem>
              </Select>
              <InputLabel id="mod-plata-label">Platit</InputLabel>
              <Select
                labelId="mod-plata-label"
                value={newSarcina.platit}
                label="Platit"
                onChange={(e) =>
                  handleNewSarcinaChange("platit", e.target.value)
                }
              >
                <MenuItem value={true}>Da</MenuItem>
                <MenuItem value={false}>Nu</MenuItem>
              </Select>
              <TextField
                style={{ marginTop: 8 }}
                label="Suma"
                onChange={(e) => handleNewSarcinaChange("suma", e.target.value)}
              />
            </div>
            <br />
            <Button
              variant="contained"
              sx={{ bgcolor: "green.light" }}
              onClick={addNewSarcinaToFormData}
            >
              Adauga Activitate
            </Button>{" "}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FoaieParcurs;
