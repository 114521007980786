import React, { useState } from "react";
import Factura from "../furnizori/factura";

import { Modal, Box, Button, TextField } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = ({ contract, furnizorId }) => {
  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [edit, setEdit] = useState(false);

  const [nume, setNume] = useState();
  const [valoare, setValoare] = useState();
  const [data, setData] = useState();

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const formattedDate = getCurrentDate();
  console.log(formattedDate);

  const [expandedInv, setExpandedInv] = useState(false);

  const handleRowClickInv = () => {
    setExpandedInv(!expandedInv);
  };

  function getTotalAmount(facturi) {
    let totalAmount = 0;

    facturi.forEach((factura) => {
      factura.avans.forEach((avans) => {
        totalAmount += avans.suma;
      });
    });

    return totalAmount;
  }

  function getRemainingAmount(contract) {
    const valoareContract = parseFloat(contract.valoareContract);
    const totalAmount = getTotalAmount(contract.facturi);
    const remainingAmount = valoareContract - totalAmount;

    return remainingAmount;
  }

  const remainingAmount = getRemainingAmount(contract);
  console.log(remainingAmount);

  console.log("factura", contract.facturi);

  const createFactura = async () => {
    const cleanedValoare = parseFloat(valoare.replace(/[\.,]/g, ""));

    const supplierData = {
      nume: nume,
      suma: cleanedValoare,
      scadenta: data,
    };

    try {
      const response = await fetch(
        `https://modvin-erb-tyu-4e6087587d89.herokuapp.com/api/suppliers/${furnizorId}/contracts/${contract._id}/invoices`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        setOpen3(!open3);
        console.log("factura244", supplierData);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td onClick={handleRowClickInv}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "800px",
              marginLeft: "50px",
            }}
          >
            <div>
              <h5>{contract.numeContract}</h5>
              <span>Facturi {contract.facturi.length}</span>
            </div>

            <span
              style={{ marginRight: "30px", marginLeft: "20px" }}
            >{`Total: ${contract.valoareContract
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Euro`}</span>

            <span style={{ marginRight: "30px" }}>{`Rest: ${remainingAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Euro`}</span>

            <span>{contract.dataContract}</span>
          </div>
        </td>
        <td>
          {" "}
          <Button variant="outlined" onClick={() => setOpen(!open)}>
            Editeaza Contract
          </Button>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
      </tr>

      {expandedInv &&
        contract.facturi.map((factura, contractIndex) => (
          <Factura
            furnizorId={furnizorId}
            contractId={contract._id}
            factura={factura}
          />
        ))}
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {!edit ? (
                  <h2>{contract.numeContract}</h2>
                ) : (
                  <input placeholder={contract.numeContract} />
                )}
              </div>
              <div>
                <Button onClick={() => setEdit(!edit)}> Edit</Button>
                {edit ? (
                  <Button onClick={() => setEdit(!edit)}> Save</Button>
                ) : null}
              </div>
            </div>
            <span style={{ fontWeight: 600 }}>Valoare:</span>{" "}
            {!edit ? (
              contract.valoareContract
            ) : (
              <input placeholder={contract.valoareContract} />
            )}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>Data Contract:</span>{" "}
            {!edit ? (
              contract.dataContract
            ) : (
              <input placeholder={contract.dataContract} />
            )}
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>Facturi: </h3>
              <Button onClick={() => setOpen3(!open3)}> Adauga Factura</Button>
            </div>
            {contract.facturi.map((contract, contractIndex) => (
              <p>
                Nume: {contract.nume} Scadenta: {contract.scadenta}{" "}
              </p>
            ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open3}
        onClose={() => setOpen3(!open3)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Factura Noua</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume Factura"
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Valoare Factura"
              onChange={(e) => setValoare(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Scadenta"
              onChange={(e) => setData(e.target.value)}
            ></TextField>
            <br />
            <br />
            <Button onClick={() => createFactura()}> Adauga Factura</Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Record;
