import React, { useState } from "react";
import { ListItem, ListItemText, Modal } from "@mui/material";
import DetaliiActivitate from "../Modals/DetaliiActivitate";

const DetaliiLuna = ({ route, routeIndex, monthIndex }) => {
  const [openRouteModal, setOpenRouteModal] = useState(false);

  const handleOpenRouteModal = () => {
    setOpenRouteModal(true);
  };

  const handleCloseRouteModal = () => {
    setOpenRouteModal(false);
  };

  function convertTimestampToDDMM(timestamp) {
    var date = new Date(timestamp);
    var day = date.getDate();
    var month = date.getMonth() + 1; // getMonth() returns month from 0-11

    // Padding day and month with zero if they are less than 10
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return `${day} - ${month}`;
  }

  return (
    <div key={routeIndex}>
      <ListItem button onClick={handleOpenRouteModal}>
        <ListItemText primary={convertTimestampToDDMM(route.createdAt)} />
      </ListItem>

      <Modal
        open={openRouteModal}
        onClose={handleCloseRouteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DetaliiActivitate route={route} />
      </Modal>
    </div>
  );
};

export default DetaliiLuna;
