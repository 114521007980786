import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./components/common/Login/AuthContext";
import { DepozitProvider } from "./components/gestiune/gestiuneEco/context/AuthContext";
import { SideMenuContext } from "./components/common/SideMenuMobile/context/AuthContext";
import { SnackbarProvider } from "notistack";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <BrowserRouter forceRefresh={true}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <AuthProvider>
        <SideMenuContext>
          <DepozitProvider>
            <App />
          </DepozitProvider>
        </SideMenuContext>
      </AuthProvider>
    </SnackbarProvider>
  </BrowserRouter>
);
