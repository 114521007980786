export const clientiTest = [
  {
    nume: "David Popescu",
    telefon: "07521234242",
    Adresa: "Str. Victoriei nr.9",
    Data: "19-11-2023",
    Lucrare: "Lucrare Proiect",
    proiect: "Proiect ",
    RTL: "RTL",
    Localitate: "Timisoara",
    smsTrimis: false,
  },
  {
    nume: "Ana Ionescu",
    telefon: "07525678901",
    Adresa: "Bd. Independentei nr.15",
    Data: "21-11-2023",
    Lucrare: "Lucrare Consultanta",
    proiect: "Proiect Consultanta Financiara",
    RTL: "RTL",
    Localitate: "Bucharest",
    smsTrimis: true,
  },
  {
    nume: "Cristina Marin",
    telefon: "07211223344",
    Adresa: "Aleea Florilor nr.7",
    Data: "23-11-2023",
    Lucrare: "Lucrare Reparatii",
    proiect: "Proiect Reparatii Locuinta",
    RTL: "RTL",
    Localitate: "Cluj-Napoca",
    smsTrimis: false,
  },
  {
    nume: "Alexandru Radu",
    telefon: "0711223344",
    Adresa: "Str. Mihai Eminescu nr.12",
    Data: "25-11-2023",
    Lucrare: "Lucrare Instalatii",
    proiect: "Proiect Instalatii Electrice",
    RTL: "RTL",
    Localitate: "Iasi",
    smsTrimis: true,
  },
  {
    nume: "Elena Popa",
    telefon: "0733445566",
    Adresa: "Piata Unirii nr.3",
    Data: "27-11-2023",
    Lucrare: "Lucrare Constructii",
    proiect: "Proiect Constructii Civile",
    RTL: "RTL",
    Localitate: "Brasov",
    smsTrimis: false,
  },
  {
    nume: "Ion Gheorghe",
    telefon: "0766777888",
    Adresa: "Bd. Republicii nr.18",
    Data: "29-11-2023",
    Lucrare: "Lucrare Design Interior",
    proiect: "Proiect Design Interior",
    RTL: "RTL",
    Localitate: "Constanta",
    smsTrimis: true,
  },
  {
    nume: "Maria Grigorescu",
    telefon: "0744555666",
    Adresa: "Aleea Frumusetii nr.6",
    Data: "01-12-2023",
    Lucrare: "Lucrare Amenajare Gradina",
    proiect: "Proiect Amenajare Gradina",
    RTL: "RTL",
    Localitate: "Sibiu",
    smsTrimis: false,
  },
  {
    nume: "Victor Andreescu",
    telefon: "0722333444",
    Adresa: "Str. Transilvaniei nr.21",
    Data: "03-12-2023",
    Lucrare: "Lucrare Reparatii Auto",
    proiect: "Proiect Reparatii Auto",
    RTL: "RTL",
    Localitate: "Galati",
    smsTrimis: true,
  },
  {
    nume: "Andreea Stoica",
    telefon: "0711222333",
    Adresa: "Bd. Dacia nr.8",
    Data: "05-12-2023",
    Lucrare: "Lucrare Marketing",
    proiect: "Proiect Strategie Marketing",
    RTL: "RTL",
    Localitate: "Ploiesti",
    smsTrimis: false,
  },
  {
    nume: "Gabriel Vladescu",
    telefon: "0755111222",
    Adresa: "Aleea Florilor nr.15",
    Data: "07-12-2023",
    Lucrare: "Lucrare IT",
    proiect: "Proiect Sisteme IT",
    RTL: "RTL",
    Localitate: "Craiova",
    smsTrimis: true,
  },
  {
    nume: "Eugen Dancu",
    telefon: "07521234242",
    Adresa: "Str. Victoriei nr.9",
    Data: "19-11-2023",
    Lucrare: "Lucrare Proiect",
    proiect: "Proiect ",
    RTL: "RTL",
    Localitate: "Timisoara",
    smsTrimis: false,
  },
  {
    nume: "Diana Mitu",
    telefon: "07525678901",
    Adresa: "Bd. Independentei nr.15",
    Data: "21-11-2023",
    Lucrare: "Lucrare Consultanta",
    proiect: "Proiect Consultanta Financiara",
    RTL: "RTL",
    Localitate: "Bucharest",
    smsTrimis: true,
  },
  {
    nume: "Dana Thus",
    telefon: "07211223344",
    Adresa: "Aleea Florilor nr.7",
    Data: "23-11-2023",
    Lucrare: "Lucrare Reparatii",
    proiect: "Proiect Reparatii Locuinta",
    RTL: "RTL",
    Localitate: "Cluj-Napoca",
    smsTrimis: false,
  },
  {
    nume: "Alexandru Radu",
    telefon: "0711223344",
    Adresa: "Str. Mihai Eminescu nr.12",
    Data: "25-11-2023",
    Lucrare: "Lucrare Instalatii",
    proiect: "Proiect Instalatii Electrice",
    RTL: "RTL",
    Localitate: "Iasi",
    smsTrimis: true,
  },
  {
    nume: "Elena Popa",
    telefon: "0733445566",
    Adresa: "Piata Unirii nr.3",
    Data: "27-11-2023",
    Lucrare: "Lucrare Constructii",
    proiect: "Proiect Constructii Civile",
    RTL: "RTL",
    Localitate: "Brasov",
    smsTrimis: false,
  },
  {
    nume: "Ion popescu",
    telefon: "0766777888",
    Adresa: "Bd. Republicii nr.18",
    Data: "29-11-2023",
    Lucrare: "Lucrare Design Interior",
    proiect: "Proiect Design Interior",
    RTL: "RTL",
    Localitate: "Constanta",
    smsTrimis: true,
  },
  {
    nume: "Maria Grigorescu",
    telefon: "0744555666",
    Adresa: "Aleea Frumusetii nr.6",
    Data: "01-12-2023",
    Lucrare: "Lucrare Amenajare Gradina",
    proiect: "Proiect Amenajare Gradina",
    RTL: "RTL",
    Localitate: "Sibiu",
    smsTrimis: false,
  },
  {
    nume: "Victor Orban",
    telefon: "0722333444",
    Adresa: "Str. Transilvaniei nr.21",
    Data: "03-12-2023",
    Lucrare: "Lucrare Reparatii Auto",
    proiect: "Proiect Reparatii Auto",
    RTL: "RTL",
    Localitate: "Galati",
    smsTrimis: true,
  },
  {
    nume: "Andreea Stoica",
    telefon: "0711222333",
    Adresa: "Bd. Dacia nr.8",
    Data: "05-12-2023",
    Lucrare: "Lucrare Marketing",
    proiect: "Proiect Strategie Marketing",
    RTL: "RTL",
    Localitate: "Ploiesti",
    smsTrimis: false,
  },
  {
    nume: "Gabriel Vladescu",
    telefon: "0755111222",
    Adresa: "Aleea Florilor nr.15",
    Data: "07-12-2023",
    Lucrare: "Lucrare IT",
    proiect: "Proiect Sisteme IT",
    RTL: "RTL",
    Localitate: "Craiova",
    smsTrimis: true,
  },
  {
    nume: "Hans Popescu",
    telefon: "07521234242",
    Adresa: "Str. Victoriei nr.9",
    Data: "19-11-2023",
    Lucrare: "Lucrare Proiect",
    proiect: "Proiect ",
    RTL: "RTL",
    Localitate: "Timisoara",
    smsTrimis: false,
  },
  {
    nume: "Ana Ionescu",
    telefon: "07525678901",
    Adresa: "Bd. Independentei nr.15",
    Data: "21-11-2023",
    Lucrare: "Lucrare Consultanta",
    proiect: "Proiect Consultanta Financiara",
    RTL: "RTL",
    Localitate: "Bucharest",
    smsTrimis: true,
  },
  {
    nume: "Deva Marin",
    telefon: "07211223344",
    Adresa: "Aleea Florilor nr.7",
    Data: "23-11-2023",
    Lucrare: "Lucrare Reparatii",
    proiect: "Proiect Reparatii Locuinta",
    RTL: "RTL",
    Localitate: "Cluj-Napoca",
    smsTrimis: false,
  },
  {
    nume: "Iulia Radu",
    telefon: "0711223344",
    Adresa: "Str. Mihai Eminescu nr.12",
    Data: "25-11-2023",
    Lucrare: "Lucrare Instalatii",
    proiect: "Proiect Instalatii Electrice",
    RTL: "RTL",
    Localitate: "Iasi",
    smsTrimis: true,
  },
  {
    nume: "Elena Alex",
    telefon: "0733445566",
    Adresa: "Piata Unirii nr.3",
    Data: "27-11-2023",
    Lucrare: "Lucrare Constructii",
    proiect: "Proiect Constructii Civile",
    RTL: "RTL",
    Localitate: "Brasov",
    smsTrimis: false,
  },
  {
    nume: "Filip Dan",
    telefon: "0766777888",
    Adresa: "Bd. Republicii nr.18",
    Data: "29-11-2023",
    Lucrare: "Lucrare Design Interior",
    proiect: "Proiect Design Interior",
    RTL: "RTL",
    Localitate: "Constanta",
    smsTrimis: true,
  },
  {
    nume: "Maria Grigorescu",
    telefon: "0744555666",
    Adresa: "Aleea Frumusetii nr.6",
    Data: "01-12-2023",
    Lucrare: "Lucrare Amenajare Gradina",
    proiect: "Proiect Amenajare Gradina",
    RTL: "RTL",
    Localitate: "Sibiu",
    smsTrimis: false,
  },
  {
    nume: "Victor Andreescu",
    telefon: "0722333444",
    Adresa: "Str. Transilvaniei nr.21",
    Data: "03-12-2023",
    Lucrare: "Lucrare Reparatii Auto",
    proiect: "Proiect Reparatii Auto",
    RTL: "RTL",
    Localitate: "Galati",
    smsTrimis: true,
  },
  {
    nume: "Andreea Stoica",
    telefon: "0711222333",
    Adresa: "Bd. Dacia nr.8",
    Data: "05-12-2023",
    Lucrare: "Lucrare Marketing",
    proiect: "Proiect Strategie Marketing",
    RTL: "RTL",
    Localitate: "Ploiesti",
    smsTrimis: false,
  },
  {
    nume: "Gabriel Vladescu",
    telefon: "0755111222",
    Adresa: "Aleea Florilor nr.15",
    Data: "07-12-2023",
    Lucrare: "Lucrare IT",
    proiect: "Proiect Sisteme IT",
    RTL: "RTL",
    Localitate: "Craiova",
    smsTrimis: true,
  },
];
