import React from "react";
import sma from "../../images/smaTitle.png";
import AdsClickIcon from "@mui/icons-material/AdsClick";

const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <div style={{ width: "60%", fontSize: "20px" }}>
        <h1>Bine ați venit!</h1>
        <img src={sma} width="500px" />

        {/* Prezentare generală */}
        <section style={{ paddingTop: "80px" }}>
          <h2>Prezentare generală</h2>
          <p>
            Bine ați venit la Smarty, soluția noastră cuprinzătoare de ERP
            proiectată pentru a ridica serviciile afacerii dvs. la noi înălțimi.
            Această demonstrație vă oferă o privire de ansamblu asupra
            numeroaselor funcționalități pe care le oferim pentru a vă
            simplifica și optimiza operațiunile.
          </p>
        </section>

        {/* Module */}
        <section style={{ paddingTop: "40px" }}>
          <h2>Module</h2>

          {/* Gestiune Transport */}
          <div>
            <p>
              <b>Gestiune Transport</b> - Modulul Gestiune Transport în Smarty
              vă permite să gestionați eficient toate aspectele legate de
              transporturile afacerii dvs. Urmați rute, monitorizați starea
              vehiculelor, optimizați planificarea și reduceți costurile
              asociate transportului. Indiferent de dimensiunea parcului auto,
              acest modul vă oferă controlul și vizibilitatea necesare.
            </p>
          </div>

          {/* Gestiune Inventar */}
          <div>
            <p>
              <b>Gestiune Inventar</b> - Cu modulul Gestiune Inventar din
              Smarty, aveți posibilitatea de a urmări și gestiona eficient toate
              bunurile și materialele din stocul dvs. Monitorizați nivelele de
              stoc în timp real, gestionați intrările și ieșirile și evitați
              pierderile. Funcționalitățile avansate vă oferă o perspectivă
              detaliată asupra performanței stocurilor dvs.
            </p>
          </div>

          {/* Gestiune Agronomie */}
          <div>
            <p>
              <b>Gestiune Agronomie</b> - Modulul Gestiune Agronomie Smarty este
              proiectat special pentru a răspunde nevoilor industriei agricole.
              Cu accent pe gestionarea eficientă a materialelor din depozite
              principale și plantatii, acest modul vă oferă instrumentele
              necesare pentru a monitoriza și gestiona cu succes resursele
              agricole și optimizați procesele agricole. Cu rapoarte detaliate
              de consum și funcționalități avansate, puteți lua decizii precise
              pentru o gestionare eficientă a activităților agricole.
            </p>
          </div>

          {/* Gestiune Baza de Date Clienti */}
          <div>
            <p>
              <b>Gestiune Baza de Date Clienti</b> - Gestiunea Bazei de Date a
              Clienților în Smarty vă oferă un instrument puternic pentru
              gestionarea și analizarea datelor despre clienți. Păstrați
              informații relevante, urmăriți istoricul interacțiunilor și
              personalizați ofertele în funcție de preferințele clienților.
              Acest modul contribuie la construirea și menținerea relațiilor
              durabile cu clienții.
            </p>
          </div>
        </section>

        {/* Cum funcționează */}
        <section style={{ paddingTop: "40px" }}>
          <h2>Cum funcționează</h2>
          <p>
            Mediul de demonstrație Smarty vă oferă posibilitatea de a explora
            scenarii simulate, reflectând situații reale de afaceri. Aici veți
            vedea cum Smarty îmbunătățește operațiunile dvs. de afaceri și
            optimizează fluxurile de lucru.
          </p>
        </section>

        {/* Cum să începeți */}
        <section style={{ paddingTop: "40px" }}>
          <h2>Cum să începeți</h2>
          <p>
            Pentru a vă personaliza experiența, vă invităm să solicitați o
            demonstrație personalizată. Echipa noastră vă va ghida prin
            funcționalitățile Smarty, evidențiind cum soluția noastră poate
            aduce beneficii specifice afacerii dvs.
          </p>
        </section>

        {/* De ce să alegeți Smarty */}
        <section style={{ paddingTop: "40px" }}>
          <h2>De ce să alegeți Smarty?</h2>
          <p>
            Smarty se mândrește cu un istoric dovedit de succes. Alăturați-vă
            comunității noastre de clienți satisfăcuți și descoperiți cum Smarty
            poate aduce valoare și eficiență în operațiunile dvs. de zi cu zi.
          </p>
        </section>

        {/* Următorul pas */}
        <section style={{ paddingTop: "40px" }}>
          <h2>Următorul pas cu Smarty!</h2>
          <p style={{ paddingBottom: "40px" }}>
            Indiferent dacă sunteți o afacere mică sau o întreprindere, Smarty
            este conceput pentru a vă împuternici. Explorați posibilitățile,
            descoperiți eficiența și ridicați-vă afacerea la noi înălțimi. Luați
            primul pas către îmbunătățirea operațiunilor dvs. de afaceri cu
            Smarty astăzi!
          </p>

          <a
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "34px",
              fontWeight: 600,
            }}
            href="mailto:frank@blockarch.ro?subject=Demo%20smarty-erp.ro&body=Buna%20ziua,%0A%0AVa%20contactez%20in%20legatura%20cu%20un%20demo%20pentru%20smarty-erp.ro"
          >
            Contactați-ne pentru o demonstrație{" "}
            <AdsClickIcon fontSize="large" />
          </a>
        </section>
        <br />
        <br />

        <br />
      </div>
    </div>
  );
};

export default HomePage;
