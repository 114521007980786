import React, { useState } from "react";
import FacturaDetails from "../furnizori/facturaDetails2";
import { Modal, Box, Button, TextField } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Factura = ({ factura, furnizorId, contractId }) => {
  const [expanded, setExpanded] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [selectFactura, setSelectFactura] = useState([]);
  const [edit, setEdit] = useState(false);

  const [nume, setNume] = useState();
  const [valoare, setValoare] = useState();
  const [data, setData] = useState();

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const formattedDate = getCurrentDate();
  console.log(formattedDate);

  function isDateAfter(dateStr) {
    const currentDate = new Date();
    const targetDate = new Date(dateStr.split("-").reverse().join("-"));

    return targetDate > currentDate;
  }

  const handleRowClick = () => {
    setExpanded(!expanded);
  };

  const handleFacturi = (x) => {
    setOpen2(!open2);
    setSelectFactura(x);
  };

  function getInvoiceAmount(factura) {
    let totalAmount = 0;

    factura.avans.forEach((avans) => {
      totalAmount += avans.suma;
    });

    return totalAmount;
  }

  function getRemainingAmount(factura) {
    const valoareAvans = getInvoiceAmount(factura);
    const totalAmount = factura.suma;
    const remainingAmount = totalAmount - valoareAvans;

    return remainingAmount;
  }

  const remainingAmount = getRemainingAmount(factura);

  const createAvans = async () => {
    const cleanedValoare = parseFloat(valoare.replace(/[\.,]/g, ""));

    const supplierData = {
      nume: nume,
      suma: cleanedValoare,
      scadenta: data,
    };

    try {
      const response = await fetch(
        `https://modvin-erb-tyu-4e6087587d89.herokuapp.com/api/suppliers/${furnizorId}/contracts/${contractId}/invoices/${factura._id}/avans`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        setOpen3(!open3);
        console.log("factura244", supplierData);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td onClick={handleRowClick}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginLeft: "150px",
              width: "80%",
            }}
          >
            <div>
              <h5>{factura.nume}</h5>
              <span>Avansuri {factura.avans.length}</span>
            </div>

            <span
              style={{ marginRight: "30px", marginLeft: "20px" }}
            >{` Total: ${factura.suma
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}  Euro`}</span>

            <span style={{ marginRight: "30px" }}>{`Rest: ${remainingAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Euro`}</span>

            {isDateAfter(factura.scadenta) ? (
              <span> {factura.scadenta}</span>
            ) : (
              <span style={{ color: "red" }}> {factura.scadenta}</span>
            )}
          </div>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
        <td>
          {" "}
          <Button onClick={() => handleFacturi(factura)}>
            Editeaza Factura
          </Button>
        </td>
      </tr>
      {expanded &&
        factura.avans.map((avans, index) => <FacturaDetails avans={avans} />)}

      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {!edit ? (
                  <h2>{selectFactura.nume}</h2>
                ) : (
                  <input placeholder={selectFactura.nume} />
                )}
                {!edit ? (
                  <p> Scadenta: {selectFactura.scadenta}</p>
                ) : (
                  <>
                    {" "}
                    <br /> <input placeholder={selectFactura.scadenta} />{" "}
                  </>
                )}
                {!edit ? (
                  <p>
                    {" "}
                    Suma:{" "}
                    {selectFactura?.suma
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                    Euro
                  </p>
                ) : (
                  <>
                    {" "}
                    <br />{" "}
                    <input
                      placeholder={selectFactura.suma
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    />{" "}
                  </>
                )}
              </div>
              <Button onClick={() => setEdit(!edit)}>Edit Factura</Button>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h4>Avans</h4>
              <Button onClick={() => setOpen3(!open3)}> Adauga Avans</Button>
            </div>
            {factura.avans.map((avans, index) => (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "40px" }}
              >
                <p>{avans.nume}</p>
                <p>{avans.suma} Euro</p>
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open3}
        onClose={() => setOpen3(!open3)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Avans Nou</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume Avans"
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Valoare Avans"
              onChange={(e) => setValoare(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder="Scadenta"
              onChange={(e) => setData(e.target.value)}
            ></TextField>
            <br />
            <br />
            <Button onClick={() => createAvans()}> Adauga Avans</Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Factura;
