import React, { useState } from "react";
import Factura from "./factura";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Box, Button, TextField } from "@mui/material";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = ({ contract }) => {
  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [edit, setEdit] = useState(false);

  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const formattedDate = getCurrentDate();
  console.log(formattedDate);

  const [expandedInv, setExpandedInv] = useState(false);

  const handleRowClickInv = () => {
    setExpandedInv(!expandedInv);
  };

  function getTotalAmount(facturi) {
    let totalAmount = 0;

    facturi.forEach((factura) => {
      Object.values(factura.luna).forEach((amount) => {
        totalAmount += amount;
      });
    });

    return totalAmount;
  }

  function getRemainingAmount(contractData) {
    const valoareContract = parseFloat(
      contractData.valoareContract.replace(/[^0-9.-]+/g, "")
    );
    const totalAmount = getTotalAmount(contractData.avans);
    const remainingAmount = valoareContract - totalAmount;

    return remainingAmount;
  }

  const remainingAmount = getRemainingAmount(contract);
  console.log(remainingAmount);

  console.log("factura");

  return (
    <React.Fragment>
      <tr>
        <td onClick={handleRowClickInv}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "350px",
              marginLeft: "50px",
            }}
          >
            <h5>{contract.nume}</h5>

            <p>{contract.valoareContract}</p>

            <p>{`${remainingAmount} Euro`}</p>
          </div>
        </td>
        <td>
          {" "}
          <Button variant="outlined" onClick={() => setOpen(!open)}>
            Editeaza Factura
          </Button>
        </td>
        {/* Add more cells for 'facturi' data if needed */}
      </tr>

      {expandedInv &&
        contract.avans.map((factura, contractIndex) => (
          <Factura factura={factura} />
        ))}
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {!edit ? (
                  <h2>{contract.nume}</h2>
                ) : (
                  <input placeholder={contract.nume} />
                )}
              </div>
              <div>
                <Button onClick={() => setEdit(!edit)}> Edit</Button>
                {edit ? (
                  <Button onClick={() => setEdit(!edit)}> Save</Button>
                ) : null}
              </div>
            </div>
            <span style={{ fontWeight: 600 }}>Valoare:</span>{" "}
            {!edit ? (
              contract.valoareContract
            ) : (
              <input placeholder={contract.valoareContract} />
            )}
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>Avans: </h3>
              <Button onClick={() => setOpen3(!open3)}> Adauga Avans</Button>
            </div>
            {contract.avans.map((contract, contractIndex) => (
              <p>
                <Button style={{ color: "red" }}>
                  <DeleteIcon />
                </Button>
                Nume: {contract.nume} Scadenta: {contract.scadenta}{" "}
              </p>
            ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open3}
        onClose={() => setOpen3(!open3)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>Adauga Avans Nou</h2>
            <br />
            <TextField type="text" placeholder="Nume Avans"></TextField>
            <br />
            <br />
            <TextField type="text" placeholder="Valoare Avans"></TextField>
            <br />
            <br />
            <TextField type="text" placeholder="Data Avans"></TextField>
            <br />
            <br />
            <Button onClick={() => setOpen3(!open3)}> Adauga Avans</Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Record;
