import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../pdfs/raportSoferi";
import { convertTime } from "../../../../utils/convertTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const FoaieParcurs = ({ openRaport, handleClose, selectedDriver }) => {
  const [currentMonth, setCurrentMonth] = useState([]);

  const monthList = selectedDriver.curse;

  console.log(monthList);

  return (
    <Modal
      open={openRaport}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            <b>Genereaza raport:</b> {selectedDriver?.nume}
          </Typography>
        </div>
        <br />
        <Typography id="modal-modal-title" variant="h6" component="h6">
          Selecteaza o luna:
        </Typography>
        {monthList?.map((month) => (
          <ListItemButton
            sx={{
              backgroundColor: currentMonth === month ? "grey" : "inherit",
              borderRadius: "8px",
              // Change the background color as needed
            }}
            onClick={() => setCurrentMonth(month)}
            key={month?.index}
          >
            <ListItemText primary={<b>{convertTime(month?.luna)}</b>} />
          </ListItemButton>
        ))}
        <br />
        {console.log('sofer', currentMonth)}


        <PDFDownloadLink
          document={
            <Factura
              sofer={currentMonth}
              lunaLucrata={currentMonth?.luna}
              selectedDriver={selectedDriver}
            />
          }
          fileName={`${"Raport "}${currentMonth?.luna}${" "}${
            selectedDriver?.nume
          }${".pdf"}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Generating PDF..."
            ) : (
              <Button variant="contained"> Genereaza Raport </Button>
            )
          }
        </PDFDownloadLink>{" "}
        <div style={{ display: "flex", flexDirection: "row" }}></div>
      </Box>
    </Modal>
  );
};

export default FoaieParcurs;
