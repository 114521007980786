import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Collapse } from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../utils/rapoarte/raportInventarCentralPDF";
import { generateExcelFile } from "./rapoarte/raportXLSX";
import "./listaGestiune.css";
import { useAuth } from "../../common/Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useNavigate } from "react-router-dom";

import { epochFormatDate } from "../../../utils/formats/epochFormat";
import AdaugaMaterialModal from "./modals/AdaugaMaterialModal";
import MutareMaterialModal from "./modals/MutareMaterialModal";
import EditMaterialModal from "./modals/EditeazaMaterialModalInventar";
import DeleteMaterialModal from "./modals/DeleteMaterialModal";

import { useSnackbar } from "notistack";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NouaLista = () => {
  const { userData } = useAuth();

  const navigate = useNavigate();

  const [openMaterial, setOpenMaterial] = useState(false);
  const [material, setMaterial] = useState([]);
  const [openMutare, setOpenMutare] = useState(false);
  const [materialId, setMaterialId] = useState();

  const [devices, setDevices] = useState([]);

  const [nume, setNume] = useState();
  const [numeFirma, setNumeFirma] = useState();
  const [model, setModel] = useState();
  const [numarInventar, setNumarInventar] = useState();

  const [categorieProdus, setCategorieProdus] = useState();
  const [dataExpirare, setDataExpirare] = useState();
  const [stoc, setStoc] = useState();

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);
  const [trigger, setTrigger] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const [avItems, setAvItems] = useState([]);

  const [desiredMaterial, setDesiredMaterial] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const uniqueCategories = [...new Set(devices.map((item) => item.categorie))];

  const filterItem = (curcat, field) => {
    const newItem = devices.filter((newVal) => {
      return newVal[field] === curcat;
    });

    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handleClose = () => {
    setOpenMaterial(false);
    setOpenMutare(false);
    setNume("");
    setNumeFirma("");
    setModel("");
    setStoc("");
  };

  const handleInputStoc = (e) => {
    const rawValue = e.target.value.replace(/\./g, "");
    setStoc(rawValue);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus();
  };

  const errorHandler = () => {
    return (
      quantity >
      selectedMaterial.stoc -
        selectedMaterial.cantitateDepozit -
        selectedMaterial.cantitateSantier
    );
  };

  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  const [formData, setFormData] = useState({});

  function updateFormData(value) {
    return setFormData((prev) => {
      return { ...prev, ...value };
    });
  }

  const createMaterial = async () => {
    const data = {
      ...formData,
      author: userData?.name,
      categorie: newCategory !== "" ? newCategory : formData.categorie,
    };

    console.log("sup", formData);

    try {
      const response = await fetch(
        `https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/inventar`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        enqueueSnackbar(`${formData.model} a fost adaugat.`, {
          variant: "success",
        });
        setOpenMaterial(!openMaterial);
        setTrigger(data);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);

    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/produse",
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setAvItems(data.data);
        } else if (response.status === 401) {
          // Handle 401 Unauthorized
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else if (response.status === 403) {
          // Handle 403 Forbidden
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else {
          alert("Access is forbidden Redirecting to login.");
        }
      } catch (error) {
        alert(`Error fetching data from: ${error}`);
      }
    };
    fetchData();
  }, [trigger, openMaterial, openMutare]); // Add navigate to dependencies if it's not causing re-renders

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/inventar",
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setDevices(data.data);
          setFilteredMaterials(data.data);
        } else if (response.status === 401) {
          // Handle 401 Unauthorized
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else if (response.status === 403) {
          // Handle 403 Forbidden
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else {
          alert("Access is forbidden Redirecting to login.");
        }
      } catch (error) {
        alert(`Error fetching data from: ${error}`);
      }
    };
    fetchData();
  }, [trigger, openMaterial, openMutare]); // Add navigate to dependencies if it's not causing re-renders

  useEffect(() => {
    setMaterialId(selectedMaterial._id);
  }, [selectedMaterial]);

  //---------------Edit feature-------------

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setFormEdit(item);
    console.log('trep', item)
    setOpenEdit(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  async function updateMaterial(e) {
    e.preventDefault();

    const editedItem = {
      producator: formEdit.producator,
      model: formEdit.model,
      serieSN: formEdit.serieSN,
      numarInventar: formEdit.numarInventar,
      categorie: newCategory !== "" ? newCategory : formEdit.categorie,
      pretA: formEdit.pretA,
      pretV: formEdit.pretV,
      stoc: formEdit.stoc,
      author: userData?.name,
    };

    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/inventar/${formEdit._id}`, {
      credentials: "include",

      method: "PUT",
      body: JSON.stringify(editedItem),
      headers: {
        "Content-Type": "application/json",
      },
    });
    enqueueSnackbar(`${formEdit.model} a fost actualizat.`, {
      variant: "success",
    });
    setTrigger(formEdit?.nume);
  }

  const handleCloseEdit = (e) => {
    updateMaterial(e);
    setOpenEdit(false);
  };

  const [openDelete, setOpenDelete] = useState();

  async function deleteMaterial() {

    const deletedItem ={
      author: userData?.name,
       producator: formEdit.producator, 
       model: formEdit.model,
      numarInventar: formEdit.numarInventar
    }
    await fetch(`https://jellyfish-app-f8wy8.ondigitalocean.app/api/vanzari/inventar/${formEdit._id}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deletedItem),

    });

    enqueueSnackbar(`${formEdit.nume} a fost eliminat.`, { variant: "error" });
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenDelete(false);
    setOpenEdit(false);
    setTrigger(formEdit?.nume);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setDesiredMaterial(item); // Set current item here
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const hanldeMutareDesired = (item) => {
    setMaterialId(item);
    setDesiredMaterial(item);
    setOpenMutare(true);
  };

  console.log('filter', filteredMaterials)
  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>Inventar</h3>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMaterial(true)}
            >
              Adauga Produs
            </Button>

            <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 8 }}>
        <PDFDownloadLink
          document={
            <Factura
              materiale={material}
              section={"inventar"}
              date={currentDate}
            />
          }
          fileName={`${"Raport "}${"inventar"}${" "}${currentDate}${".pdf"}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Generating PDF..."
            ) : (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "blue.focus", // normal background color
                  "&:hover": {
                    bgcolor: "blue.focusHover", // hover background color
                  },
                }}
              >
                {" "}
                Genereaza Raport Inventar
              </Button>
            )
          }
        </PDFDownloadLink>{" "}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",

        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "green.light", // normal background color
            "&:hover": {
              bgcolor: "green.dark", // hover background color
            },
          }}
          onClick={() => generateExcelFile(material, currentDate)}
        >
          Genereaza Raport XLSX
        </Button>
      </div>

            {/* <TextField
              type="text"
              placeholder="Cauta dupa nume..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="desktop-grid-agro">
        <div className="desktop-agro">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
          </div>

          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setFilteredMaterials(devices)}
            >
              Toate
            </Button>
          </div>

          {uniqueCategories.map((category) => (
            <div style={{ marginTop: "8px" }}>
              <Button
                key={category}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
                onClick={() => filterItem(category, "categorie")}
              >
                {category}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <div className="mobile-agro">
            <Button
              variant="contained"
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
            >
              Categorii
            </Button>
            <Collapse in={openCategoryMobile}>
              <div
                style={{
                  borderRight: "1px solid #ccc",
                  padding: "12px",
                  borderRadius: "4px",
                  marginRight: "4px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "grey.dark",
                      color: "white", // normal background color
                      "&:hover": {
                        bgcolor: "grey.light",
                        color: "black", // hover background color
                      },
                      width: "20%",
                    }}
                    onClick={() => handleAllCategory(devices)}
                  >
                    Toate
                  </Button>
                </div>

                {uniqueCategories.map((category) => (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      key={category}
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: "grey.dark",
                        color: "white", // normal background color
                        "&:hover": {
                          bgcolor: "grey.light",
                          color: "black", // hover background color
                        },
                        width: "20%",
                      }}
                      onClick={() => filterItem(category, "categorie")}
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          <div style={{  overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    Producator
                  </th>
                  <th style={{ paddingRight: "40px" }}>Model</th>
                  <th style={{ paddingRight: "40px" }}>Categorie</th>
                  <th style={{ paddingRight: "40px" }}>Numar inventar</th>
                  <th style={{ paddingRight: "40px" }}>Serie S/N</th>
                  <th style={{ paddingRight: "40px" }}>Pret Achizitie</th>
                  <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
                  <th style={{ paddingRight: "40px" }}>Stoc Depozit</th>
                  <th style={{ paddingRight: "40px" }}>Actiuni</th>
                </tr>
              </thead>
              <tbody>
                {filteredMaterials.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                      <Tooltip
                        title={
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            adaugat de {item.author} la{" "}
                            {formatCustomDateString(item.createdAt)}
                          </Typography>
                        }
                        placement="right"
                      >
                        <span>{item.producator}</span>
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.model}</td>
                    <td style={{ paddingRight: "40px" }}>{item.categorie}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {item.numarInventar}
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.serieSN}</td>
                    <td style={{ paddingRight: "40px" }}>{item.pretA}</td>
                    <td style={{ paddingRight: "40px" }}>{item.pretV}</td>

                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.stoc)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {/* <Button
                        variant="contained"
                        sx={{
                          bgcolor: "blue.dark", // normal background color
                          "&:hover": {
                            bgcolor: "blue.light", // hover background color
                          },
                        }}
                        onClick={() => handleEditForm(item)}
                      >
                        Edit
                      </Button> */}

                      <div>
                        <Button
                          id="composition-button"
                          aria-haspopup="true"
                          sx={{ color: "black" }}
                          onClick={(event) => handleClick(event, item)} // Pass item her
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid black",
                              borderRadius: "12px",
                              backgroundColor: "white",
                              marginRight: "14px",
                            }}
                          >
                            <KeyboardArrowDownIcon />
                          </div>
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          PaperProps={{
                            elevation: 0, // Removes the shadow
                            sx: {
                              overflow: "visible",
                              filter: "none", // Removes any filter effects like shadows or blurring
                              // Controls the margin to adjust the position as needed
                              "& .MuiAvatar-root": {
                                width: 42,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              borderRadius: "4px",
                              width: 100,
                              border: "1px solid #D9DCE1",
                            },
                          }}
                        >
                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              handleEditForm(desiredMaterial);
                              handleCloseMenu();
                              console.log('selc', item)
                            }}
                          >
                            Edit
                          </MenuItem>

                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              hanldeMutareDesired(desiredMaterial);
                              handleCloseMenu();
                            }}
                          >
                            Muta
                          </MenuItem>
                          {/* <MenuItem
                            key={index + 2}
                            onClick={() => {
                              // setOpenEliminare(true);
                              handleCloseMenu();
                            }}
                          >
                            Elimina
                          </MenuItem> */}
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <InteractiveSVG imageUrl={mapSvg} />
      </div> */}

      <AdaugaMaterialModal
        {...{
          openMaterial,
          handleModalMaterialClose,
          setNume,
          setNumeFirma,
          setModel,
          setNumarInventar,
          updateForm,
          formEdit,
          uniqueCategories,
          setNewCategory,
          newCategory,
          dataExpirare,
          setDataExpirare,
          stoc,
          handleInputStoc,
          createMaterial,
          styleInv,
          updateFormData,
          formData,
        }}
      />

      <EditMaterialModal
        {...{
          openEdit,
          setOpenEdit,
          formEdit,
          updateForm,
          uniqueCategories,
          setNewCategory,
          newCategory,
          handleCloseEdit,
          setOpenDelete,
          userData,
          formatCustomDateString, // Assuming this is a function passed as a prop
          styleInv,
        }}
      />

      <DeleteMaterialModal
        {...{
          openDelete,
          setOpenDelete,
          formEdit,
          handleDeleteMaterial,
          styleInv,
        }}
      />
    </>
  );
};

export default NouaLista;
