import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "flex-start",
    width: '100%'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: '100%'
  },
  table: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    margin: 10,
    height: 'auto'
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#7d7d7d',
  },
  row: {
    flexDirection: 'row',
  },
  headerCell: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#000',
    padding: 8,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  cell: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#000',
    padding: 8,
    textAlign: 'center',
  },
  stripedRow: {
    flexDirection: 'row',
    backgroundColor: '#f9f9f9', // Set the background color for striped rows
  },
});


  const Contract = ({ sofer, lunaLucrata, selectedDriver }) => {
    const tables = [];
    const itemsPerTable = 18;


  
    for (let i = 0; i < sofer?.zileLucrate?.length; i += itemsPerTable) {
      const currentTableData = sofer?.zileLucrate?.slice(i, i + itemsPerTable);
      const currentTable = (
        <View key={i} style={styles.table}>
          <View style={styles.headerRow}>
            <Text style={styles.headerCell}>Ziua plecare (zz-ll)</Text>
            <Text style={styles.headerCell}>Ziua sosire (zz-ll)</Text>
            <Text style={styles.headerCell}>Masina</Text>
            <Text style={styles.headerCell}>Parcurs km</Text>

          </View>
          {currentTableData.map((item, index) => (
            // Use stripedRow style for alternate rows
            <View key={index} style={index % 2 === 0 ? styles.row : styles.stripedRow}>
              <Text style={styles.cell}>{item.plecareZiuaLuna}</Text>
              <Text style={styles.cell}>{item.sosireZiuaLuna}</Text>
              <Text style={styles.cell}>{item.masina}</Text>
              <Text style={styles.cell}>{item.parcursRealizat}</Text>
            </View>
          ))}
        </View>
      );
  
      tables.push(currentTable);
          }
  
    return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text> Raport - {selectedDriver.nume} </Text>
        <Text> {` `}</Text>
        <Text> Luna {lunaLucrata} </Text>

        <Text> {` `}</Text>

        <Text>Pe baza zilelor lucrate: </Text>
        <br />
        <Text> {` `}</Text>

{tables}
      </View>
    </Page>
  </Document>
)

      }

export default Contract;
