import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const stylePatrat = {
  display: "flex",

  border: "1px solid black",
  flexDirection: "column",
  padding: "10px",
  gap: "5px",
};

const DetaliiActivitate = ({ route }) => {
  const selectedDriver = route;
  return (
    <Box sx={style}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Activitate: {selectedDriver?.sofer}
        </Typography>
      </div>
      <br />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <div style={stylePatrat}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              1. Codul unitatii de transport
            </Typography>
            <br />
            <div>
              <TextField value={selectedDriver?.sofer} />
              <TextField value={selectedDriver?.masina} />
            </div>

            <div>
              <TextField
                value={
                  selectedDriver?.ajutorSofer
                    ? selectedDriver?.ajutorSofer
                    : "Nu exista ajutor"
                }
              />
              <TextField
                value={
                  selectedDriver?.locParcare
                    ? selectedDriver?.locParcare
                    : "Nu exista parcare"
                }
              />
            </div>
          </div>

          <div style={stylePatrat}>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                3. Sarcini de transport
              </Typography>
              <br />

              {selectedDriver?.sarcini?.map((sarcina, index) => (
                <div key={index} style={{ padding: "5px" }}>
                  <TextField value={sarcina?.expeditor} />
                  <TextField value={sarcina?.destinatiaExpA} />
                  <TextField value={sarcina?.destinatiaExpB} />{" "}
                </div>
              ))}

              <br />

              <br />
            </div>
          </div>

          <div style={stylePatrat}>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                5. Confirmarea starii tehnice
              </Typography>
              <br />
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  La sosire
                </Typography>
                <TextField value={selectedDriver?.sosireKilometraj} />
                <TextField value={selectedDriver?.sosireZiuaLuna} />
                <TextField value={selectedDriver?.sosireOraMin} />
              </div>
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  La plecare
                </Typography>
                <TextField value={selectedDriver?.plecareKilometraj} />
                <TextField value={selectedDriver?.plecareZiuaLuna} />
                <TextField value={selectedDriver?.plecareOraMin} />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField value={selectedDriver?.parcursRealizat} />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DetaliiActivitate;
