import React from "react";
import "./Spinner.css";
import SmartySpinner from "../../../images/smaSpinner.png";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <img className="spinner" src={SmartySpinner} alt="Loading" />
      <br />
      <h4>Datele se incarca...</h4>
    </div>
  );
};

export default Spinner;
