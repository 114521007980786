import React, { useState } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
} from "@mui/material";
import Alimentare from "../Modals/FoaieAlimentare";

const ConsumComponent = ({
  selectedDriver,
  handleInputChange,
  handleInputChangeNewAlimentare,
  handleAddNewAlimentare,
}) => {
  const [openConsumIndices, setOpenConsumIndices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleConsum = (index) => {
    setOpenConsumIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <List
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "4px",
            width: "85%",
          }}
        >
          <ListItem>
            <ListItemText primary="Alimentari:" />
          </ListItem>
          {selectedDriver?.alimentare?.map((each, index) => (
            <div key={each.index}>
              <ListItem button onClick={() => toggleConsum(index)}>
                {" "}
                <ListItemText
                  primary={`${each?.benzinarie} cu ${each?.suma} Lei in data de ${each?.dataAlimentare}`}
                />
              </ListItem>
              <Collapse in={openConsumIndices.includes(index)}>
                <Paper elevation={1} style={{ marginBottom: 2 }}>
                  <ListItem>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ListItemText
                        primary={`S-a alimentat cu ${
                          each?.cantitate
                        } litri cu ${each?.suma} Lei la pretul de (${(
                          each?.suma / each?.cantitate
                        ).toFixed(2)}) Lei/litru`}
                      />
                    </div>
                  </ListItem>
                </Paper>
              </Collapse>
            </div>
          ))}
        </List>
        <div
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          <Button variant="contained" onClick={handleOpen}>
            Adauga Alimentare
          </Button>
        </div>
      </div>
      <Alimentare
        {...{
          open,
          handleClose,
          selectedDriver,
          handleInputChange,
          handleInputChangeNewAlimentare,
          handleAddNewAlimentare,
        }}
      />{" "}
    </>
  );
};

export default ConsumComponent;
