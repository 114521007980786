import React, { useState } from "react";
import { Paper, List, ListItem, ListItemText } from "@mui/material";
import HartaMasini from "./HartaMasini";
import Spinner from "../../common/Spinner/Spinner";

const OverviewMasini = ({ dateMasini, masini2, isLoading }) => {
  const [selectedDriverLocation, setSelectedDriverLocation] = useState({
    latitude: "",
    longitude: "",
  });

  const [selectedDriverData, setSelectedDriverData] = useState();

  const combinedData =
    dateMasini?.positionList &&
    masini2?.deviceList &&
    dateMasini?.positionList?.map((car) => ({
      ...car,
      carModel: masini2?.deviceList.find(
        (model) => model.deviceId === car.deviceId
      ),
    }));

  const insertHyphensAroundNumbers = (inputString) => {
    return inputString.replace(/(\d+)/g, "-$1-");
  };

  console.log("masini2", combinedData);

  console.log(combinedData);

  const handleSelectDriver = (each) => {
    setSelectedDriverLocation({
      latitude: each.coordinate.latitude,
      longitude: each.coordinate.longitude,
    });
    setSelectedDriverData(each);
  };

  return (
    <div style={{ height: "100%" }}>
      <h3>Lista Masini</h3>

      {isLoading ? (
        <Spinner />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            height: "100%",
          }}
        >
          <div>
            <List>
              {combinedData?.map((each, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{
                    marginBottom: 8,
                    color:
                      each.ignitionState === "UNKNOWN"
                        ? "black"
                        : each.ignitionState === "ON"
                        ? "green"
                        : "red",
                  }}
                >
                  <ListItem onClick={() => handleSelectDriver(each)} button>
                    <ListItemText
                      primary={`${insertHyphensAroundNumbers(
                        each.carModel.deviceName
                      )} - ${each.ignitionState}`}
                    />
                  </ListItem>
                </Paper>
              ))}
            </List>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            {selectedDriverData && (
              <HartaMasini
                key={`${selectedDriverLocation.latitude}-${selectedDriverLocation.longitude}`}
                {...{ selectedDriverLocation, selectedDriverData }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewMasini;
