import { createContext, useContext, useEffect, useState } from "react";

const ContextDepozite = createContext();

export const DepozitProvider = ({ children }) => {
  const [isNewDepozit, setIsNewDepozit] = useState();

  return (
    <ContextDepozite.Provider value={{ isNewDepozit, setIsNewDepozit }}>
      {children}
    </ContextDepozite.Provider>
  );
};

export const useDepozitContext = () => {
  return useContext(ContextDepozite);
};
