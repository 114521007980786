import React, { useState, useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const IstoricTabel = ({ url }) => {
  const navigate = useNavigate();
  const [istoric, setIstoric] = useState([]);

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);
    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();
    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, {
          credentials: "include",
        });
        if (res.ok) {
          const data = await res.json();
          setIstoric(data);
        } else if (res.status === 401) {
          // Handle 401 Unauthorized
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else if (res.status === 403) {
          // Handle 403 Forbidden
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else {
          console.error("Response not OK:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [url]); // Include `history` in the dependency array

  return (
    <div key={url}>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div>
          <h3>Istoric</h3>
          <br />
          <br />
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ paddingRight: "20px", paddingLeft: "30px" }}>
                Actiune
              </th>
              <th style={{ paddingRight: "40px" }}>Produs/Comanda</th>
              <th style={{ paddingRight: "20px" }}>Numar inventar</th>
              <th style={{ paddingRight: "20px" }}>User</th>
              <th style={{ paddingRight: "20px" }}>Data</th>

              <th style={{ paddingRight: "40px" }}>Descriere</th>
            </tr>
          </thead>
          <tbody>
            {istoric.map((item, index) => (
              <tr key={index}>
                <td style={{ paddingRight: "20px", paddingLeft: "30px" }}>
                  <Tooltip
                    title={
                      <Typography
                        variant="body2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        adaugat de {item.author} la{" "}
                        {formatCustomDateString(item.createdAt)}
                      </Typography>
                    }
                    placement="right"
                  >
                    <span>{item?.actiune}</span>
                  </Tooltip>
                </td>
                <td style={{ paddingRight: "40px" }}>
                  {item?.producator
                    ? `${item?.producator} ${item?.model}`
                    : item.comandaId}
                </td>
                <td style={{ paddingRight: "20px" }}>{item?.numarInventar}</td>
                <td style={{ paddingRight: "20px" }}>{item?.author}</td>
                <td style={{ paddingRight: "20px" }}>
                  {" "}
                  {formatCustomDateString(item.createdAt)}
                </td>

                <td style={{ paddingRight: "40px" }}>
                  {item?.descriereActiune}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IstoricTabel;
