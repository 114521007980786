import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

export default function AdaugaSofer({
  openSoferModal,
  handleClose,
  handleInputChangeNewSofer,
  handleAddNewSofer,
}) {
  const handleLocalChange = () => {
    handleAddNewSofer();
    handleClose();
  };
  return (
    <div>
      <Modal
        open={openSoferModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adauga Sofer Nou
          </Typography>
          <br />
          <>
            <TextField
              id="outlined-basic"
              label="Nume"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewSofer("nume", e.target.value)
              }
            />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Telefon"
              variant="outlined"
              onChange={(e) =>
                handleInputChangeNewSofer("telefon", e.target.value)
              }
            />
            <br />
            <br />
          </>

          <Button variant="contained" onClick={handleLocalChange}>
            Adauga sofer
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
