import React, { useState } from "react";
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import CursaComponent from "./Detalii/CursaComponent";
import ConsumComponent from "./Detalii/ConsumComponent";
import DetaliiComponent from "./Detalii/DetaliiComponent";
import AdaugaSofer from "./Modals/AdaugaSofer";

const OverviewSoferi = ({
  dateSoferiBulk,
  handleNewActivity,
  handleInputChange,
  formData,
  setFormData,
  handleInputChangeNewSofer,
  handleAddNewSofer,
  handleInputChangeNewAlimentare,
  handleAddNewAlimentare,
  handleDriverId,
  trigger,
}) => {
  const [selectedDriver, setSelectedDriver] = useState([]);

  const [openSoferModal, setOpenSoferModal] = useState(false);

  const handleDriverData = (driver) => {
    handleDriverId(driver?._id);
    setSelectedDriver(driver);
  };

  console.log(dateSoferiBulk, trigger);

  const handleOpenSoferModal = () => setOpenSoferModal(true);
  const handleClose = () => setOpenSoferModal(false);
  return (
    <div>
      <h3>Lista Soferi</h3>

      <div style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
        <div>
          <List>
            {dateSoferiBulk?.map((each, index) => (
              <Paper
                key={each?.index}
                elevation={3}
                style={{ marginBottom: 8 }}
              >
                <ListItemButton
                  onClick={() => handleDriverData(dateSoferiBulk[index])}
                  button
                >
                  <ListItemText primary={each?.nume} />
                </ListItemButton>
              </Paper>
            ))}
            <Button variant="contained" onClick={handleOpenSoferModal}>
              Adauga sofer
            </Button>
          </List>
        </div>
        <div style={{ padding: "10px" }}>
          {selectedDriver?.nume && (
            <Paper elevation={3} style={{ padding: 8 }}>
              <DetaliiComponent selectedDriver={selectedDriver} />

              <CursaComponent
                {...{
                  handleNewActivity,
                  handleInputChange,
                  formData,
                  selectedDriver,
                  setFormData,
                  trigger,
                }}
              />
              <br />
              <ConsumComponent
                {...{
                  handleNewActivity,
                  handleInputChange,
                  formData,
                  selectedDriver,
                  handleInputChangeNewAlimentare,
                  handleAddNewAlimentare,
                }}
              />
            </Paper>
          )}
        </div>
      </div>

      <AdaugaSofer
        {...{
          openSoferModal,
          handleClose,

          handleInputChangeNewSofer,
          handleAddNewSofer,
        }}
      />
    </div>
  );
};

export default OverviewSoferi;
