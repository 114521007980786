import { createContext, useContext, useState } from "react";

const ContextSideMenu = createContext();

export const SideMenuContext = ({ children }) => {
  const [mobileMenuOn, setMobileMenuOn] = useState(false);

  return (
    <ContextSideMenu.Provider value={{ mobileMenuOn, setMobileMenuOn }}>
      {children}
    </ContextSideMenu.Provider>
  );
};

export const useSideMenuContext = () => {
  return useContext(ContextSideMenu);
};
