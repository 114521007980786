import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const MaterialModal = ({
  openMaterial,
  handleModalMaterialClose,
  setNume,
  setNumeFirma,
  setModel,
  setNumarInventar,
  updateForm,
  formEdit,
  uniqueCategories,
  setNewCategory,
  newCategory,
  dataExpirare,
  setDataExpirare,
  stoc,
  handleInputStoc,
  createMaterial,
  styleInv,
}) => {
  return (
    <Modal
      open={openMaterial}
      onClose={handleModalMaterialClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleInv}>
        <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
          <h2>Adauga Material Nou</h2>
          <br />
          <TextField
            type="text"
            placeholder="Nume Material"
            onChange={(e) => setNume(e.target.value)}
          ></TextField>
          <TextField
            type="text"
            placeholder="Firma material"
            onChange={(e) => setNumeFirma(e.target.value)}
          ></TextField>
          <TextField
            type="text"
            placeholder="Model"
            onChange={(e) => setModel(e.target.value)}
          ></TextField>
          <TextField
            type="text"
            placeholder="Numar inventar"
            onChange={(e) => setNumarInventar(e.target.value)}
          ></TextField>
          <br />
          <br />

          <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
          <Select
            label="Categorie"
            value={formEdit.categorie}
            onChange={(e) => {
              updateForm({ categorie: e.target.value });
              setNewCategory(""); // Reset the new category input when changing the selection
            }}
          >
            {uniqueCategories.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
            <MenuItem value={"adauga"}>Adauga Categorie Noua</MenuItem>
          </Select>

          {formEdit.categorie === "adauga" && (
            <TextField
              type="text"
              placeholder="Categorie"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Data expirare"
                value={dataExpirare}
                onChange={(newValue) => setDataExpirare(newValue)}
                format="DD-MM-YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            type="text"
            placeholder="Stoc"
            value={stoc}
            onChange={handleInputStoc}
          ></TextField>

          <br />
          <br />
          <Button
            variant="contained"
            sx={{ bgcolor: "green.light" }}
            onClick={() => createMaterial()}
          >
            {" "}
            Adauga Material
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default MaterialModal;
